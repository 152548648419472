import { makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  header: {
    textAlign: "center",
    margin: theme.spacing(3)
  }
}))

const PrivacyEn = () => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h4" className={classes.header}>
        VisualAlpha
        <br />
        Privacy Policy
      </Typography>
      <p>
        This privacy policy will explain how Visual Alpha uses the personal data
        we collect from you when you visit our website.
      </p>

      <Typography variant="h6">What data do we collect?</Typography>
      <p>Visual Alpha collects the following data:</p>
      <ul>
        <li>
          Personal identification information (Name, e-mail address, telephone
          number, etc).
        </li>
      </ul>

      <Typography variant="h6">How do we collect your data?</Typography>
      <p>
        You directly provide Visual Alpha with most of the data we collect. We
        collect data and process data when you:
      </p>
      <ul>
        <li>Register online or agree to use one of our services.</li>
        <li>
          Voluntarily complete a customer survey or provide feedback via another
          channel.
        </li>
        <li>Use or view www.visual-alpha.com via your browser's cookies.</li>
      </ul>

      <Typography variant="h6">How will we use your data?</Typography>
      <p>Visual Alpha collects your data so that we can:</p>
      <ul>
        <li>Process your order and manage your account.</li>
        <li>
          E-mail you with special offers on services we think you might like.
        </li>
        <li>Use it to better understand how you use our website.</li>
      </ul>

      <Typography variant="h6">How do we store your data?</Typography>
      <p>
        Visual Alpha securely stores your data on our cloud-based servers.
        Encryption is used throughout to prevent unauthorized access to your
        data.
      </p>

      <Typography variant="h6">
        What are your data protection rights?
      </Typography>
      <p>
        Visual Alpha would like to make sure you are fully aware of al your data
        protection rights. Every user is entitled to the following:
      </p>
      <ul>
        <li>
          The right to access: you have the right to request Visual Alpha
          provide copies of your personal data. We may charge you a small fee
          for this service.
        </li>
        <li>
          The right to rectification: you have the right to request that Visual
          Alpha correct any information you believe is inaccurate. You have the
          right to request Visual Alpha to complete information that you believe
          is incomplete.
        </li>
        <li>
          The right to erasure: you have the right to request that Visual Alpha
          erase your personal data, under certain conditions.
        </li>
        <li>
          The right to restrict processing: you have the right to request that
          Visual Alpha restrict the processing of your personal data, under
          certain conditions.
        </li>
        <li>
          The right to data portability: you have teh right to request that
          Visual Alpha transfer the data that we have collected to another
          organization, or directly to you, under certain conditions. If you
          make a request, we have one month to respond to you. If you would like
          to exercise any of these rights, please contact us at our e-mail:
          <a href="mailto:privacy@visual-alpha.com">privacy@visual-alpha.com</a>
          .
        </li>
      </ul>

      <Typography variant="h6">What are cookies?</Typography>
      <p>
        Cookies are text files placed on your computer to collect standard
        Internet log information and visitor behaviour information. When you
        visit our websites, we may collect information from you automatically
        through cookies or similar technology.
      </p>

      <Typography variant="h6">How do we use cookies?</Typography>
      <p>
        Visual Alpha uses cookies in a range of ways to improve your experience
        on our website, including:
      </p>
      <ul>
        <li>Keeping you signed in.</li>
        <li>Understanding how you use our website.</li>
      </ul>

      <Typography variant="h6">What types of cookies do we use?</Typography>
      <p>
        There are a number of different types of cookies, however, our website
        uses:
      </p>
      <ul>
        <li>
          Functionality: our company uses these cookies so that we can recognize
          you on our website, and remember your previously selected preferences.
          These could include what language you prefer, and what location you
          are in. A mix of first-party and third-party cookies are used.
        </li>
      </ul>

      <Typography variant="h6">How to manage cookies</Typography>
      <p>
        You can set your browser not to accept cookies. However, in some cases
        our website features may not function as a result.
      </p>

      <Typography variant="h6">Privacy policies of other websites</Typography>
      <p>
        The Visual Alpha website contains links to other websites. Our privacy
        policy applies only to our website, so if you click on a link to another
        website, you should read their privacy policy.
      </p>

      <Typography variant="h6">Changes to our privacy policy</Typography>
      <p>
        Visual Alpha keeps its privacy policy under regular review and places
        any updates on this web page. Ths privacy policy was last updated on the
        20th April 2020.
      </p>

      <Typography variant="h6">How to contact us</Typography>
      <p>
        If you have any question about Visual Alpha's privacy policy, the data
        we hold on you, or you would like to exercise one of your data
        protection rights, please do not hesitate to e-mail us at
        <a href="mailto:privacy@visual-alpha.com">privacy@visual-alpha.com</a>.
      </p>

      <Typography variant="h6">
        How to contact the appropriate authority
      </Typography>
      <p>
        Should you wish to report a complain, or if you feel that Visual Alpha
        has not addressed your concern in a satisfactory manner, you may contact
        the Information Commissioner's Office.
      </p>
    </div>
  )
}

export default PrivacyEn
