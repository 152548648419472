import Error from "@/pages/error/Error"
import React from "react"
import { withRouter } from "react-router"

class ErrorBoundary extends React.Component {
  unlisten: (() => void) | undefined
  state: {
    hasError?: any
    error?: any
    unauthorized?: any
    notfound?: any
    page?: any
    pages?: any
    columnsButtonAnchorEl?: any
    exportButtonAnchorEl?: any
    isChecked?: any
    defaultColumnWidths?: any
    columns?: any
    rows?: any
    expandedRowIds?: any
  }
  props: any

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false })
      }
    })
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten()
    }
  }

  /*
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo)
  }
  */

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error errorMessage={this.props.message} />
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
