import { PackageType } from "@/__generated__/globalTypes"
import useAuth from "@/components/auth/useAuth"
import React from "react"

type ModuleSectionProps = {
  children: any
  //! This is or based
  module: string | null | undefined | (string | null | undefined)[]
  organisationPackage?:
    | Partial<keyof typeof PackageType>
    | Partial<keyof typeof PackageType>[]
}
/**
 * Module and Organisation Package Access Contro
 * @param module - array/string of modules - OR not AND based
 * @param organisationPackage - array/ string of PackageType - OR not AND based
 */
const ModuleSection = React.forwardRef((props: ModuleSectionProps, ref) => {
  const { children, module, organisationPackage } = props
  const { token, user } = useAuth()
  const isDev = process.env.REACT_APP_ENVIRONMENT === "dev"
  const isStaging = process.env.REACT_APP_ENVIRONMENT === "staging"
  const isLocal = process.env.REACT_APP_ENVIRONMENT === "local"

  if (!token) {
    /*
    // not logged in so redirect to login page with the return url
    return (
      <Redirect to={{ pathname: "/login", state: { from: location } }} />
    )
    */

    return null
  }

  //Wait for profile to load
  if (!user) return null

  //Check user belongs to an organisation
  if (!user.organisation) return null

  //Check if the organisation has any modules
  if (!user.organisation.modules) return null

  //Array wrap module
  const modules = Array.isArray(module) ? module : [module]
  const organisationPackages = Array.isArray(organisationPackage)
    ? organisationPackage
    : [organisationPackage]

  //Check if the organisation has any of the required modules
  if (!user.organisation.modules.some(r => modules.includes(r))) return null

  if (
    organisationPackage &&
    !user.organisation?.organisationPackages?.data.some(organisationPackage =>
      organisationPackages.includes(organisationPackage?.type)
    )
  )
    return null

  if (modules.includes("demo") && !(isDev || isStaging || isLocal)) return null

  // authorised so return component
  return children
})

export default ModuleSection
