import { InputAdornment } from "@material-ui/core"
import BaseTextField from "@material-ui/core/TextField"
import { Clear } from "@material-ui/icons"
import { FieldInputProps, FormikProps, getIn } from "formik"
import React, { useRef } from "react"
import IconButton from "../../button/IconButton"

type TextFieldProps = {
  label: string
  field: FieldInputProps<any>
  form: FormikProps<any>
  inputProps: object
  margin?: "normal"
  clearable?: boolean
}

export default function TextField(props: TextFieldProps) {
  const {
    label,
    field: { onChange, ...field },
    form: { touched, errors, status, setStatus, setFieldValue },
    inputProps = {},
    margin = "normal",
    clearable = false,
    ...other
  } = props
  const inputReference = useRef<HTMLInputElement>(null)
  const setFocus = () => {
    inputReference.current?.focus()
  }

  const errorText =
    getIn(errors, field.name) || (status && getIn(status, field.name))

  const hasError =
    (getIn(touched, field.name) && errorText !== undefined) ||
    (status && getIn(status, field.name) !== undefined)

  const handleChange = event => {
    if (status) {
      delete status[field.name]

      setStatus(status)
    }

    onChange(event)
  }

  const clear_input = e => {
    setFieldValue(field.name, "")
    setFocus()
  }

  return (
    <BaseTextField
      label={label}
      error={hasError}
      helperText={hasError ? errorText : ""}
      onChange={handleChange}
      inputProps={{ ...inputProps, "data-testid": field.name }}
      margin={margin}
      inputRef={inputReference}
      InputProps={{
        endAdornment: clearable ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={e => clear_input(e)}
              edge="end"
            >
              {<Clear />}
            </IconButton>
          </InputAdornment>
        ) : null,
        ...inputProps
      }}
      {...field}
      {...other}
    />
  )
}
