import { UserRole } from "@/__generated__/globalTypes"
import useSnackbar from "@/helpers/add-snackbar"
import { usePersistedState } from "@/helpers/state"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { Button } from "@material-ui/core"
import { Field, FormikHelpers } from "formik"
import React, { useState } from "react"
import { useHistory } from "react-router"
import { Preferences } from "../auth/AuthContext"
import useAuth from "../auth/useAuth"
import FormDialog from "../util/dialog/FormDialog"
import GraphError from "../util/error/GraphError"
import AutoComplete from "../util/form/fields/AutoComplete"
import Loading from "../util/Loading"
import { getOrganisationsSimple } from "./__generated__/getOrganisationsSimple"
import {
  StartImpersonation,
  StartImpersonationVariables
} from "./organisations/__generated__/StartImpersonation"
import { START_IMPERSONATION } from "./organisations/ImpersonateUserDialog"

export const GET_ORGANISATIONS_SIMPLE = gql`
  query getOrganisationsSimple {
    organisations(first: 2000, page: 1) {
      data {
        id
        name
        ref
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`

const ChangeOrg = () => {
  const { user } = useAuth()
  const history = useHistory()
  const { addSnackbar } = useSnackbar()
  const [open, setOpen] = useState<boolean>(false)
  const [, setPreferences] = usePersistedState<Preferences | undefined>(
    "preferences",
    true
  )

  const [startImpersonation, { client }] = useMutation<
    StartImpersonation,
    StartImpersonationVariables
  >(START_IMPERSONATION)

  const [triggerGetOrganisationsSimple, { loading, data, error }] =
    useLazyQuery<getOrganisationsSimple>(GET_ORGANISATIONS_SIMPLE, {
      context: { module: "admin" }
    })

  if (error) return <GraphError error={error} />

  const organisations = data?.organisations?.data ?? []

  const organisationOptions =
    organisations?.map(e => ({
      label: e?.ref ?? e.name,
      value: e?.id,
      ref: e?.ref ?? e.name
    })) ?? []

  return (
    <>
      <Button
        style={{ backgroundColor: "bisque" }}
        onClick={() => {
          triggerGetOrganisationsSimple()
          setOpen(true)
        }}
      >
        Change
      </Button>
      <FormDialog
        title={"Impersonate"}
        initialValues={organisationOptions?.[0] ?? []}
        onClickLabel="Impersonate"
        displayFormState
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        onSubmit={(
          values,
          { setSubmitting, setErrors }: FormikHelpers<unknown>
        ) => {
          startImpersonation({
            variables: {
              organisationId: values?.ref?.value,
              role: UserRole.admin
            }
          })
            .then(data => data?.data?.startImpersonation?.organisation?.assets)
            .then(assets => {
              setPreferences({
                asOfDate: user?.preferences?.asOfDate ?? user?.asOfDate ?? "",
                assetId: assets?.data[0]?.id ?? "",
                assetType: assets?.data[0]?.type ?? null,
                useLatestAsOfDate: user?.preferences?.useLatestAsOfDate ?? true
              })
              client.resetStore()
            })
            .then(() => setSubmitting(false))
            .then(() => addSnackbar("User Impersonated"))
            .then(() => history.go(0))
            .catch(error => {
              if (
                error.graphQLErrors &&
                error.graphQLErrors[0]?.extensions?.validation
              ) {
                setSubmitting(false)
                setErrors(error.graphQLErrors[0]?.extensions.validation)
              } else {
                setSubmitting(false)
              }
            })
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Field
            name={"ref"}
            label={"ref"}
            component={AutoComplete}
            margin="normal"
            options={organisationOptions}
            fullWidth
            variant="filled"
          />
        )}
      </FormDialog>
    </>
  )
}

export default ChangeOrg
