import useAuth from "@/components/auth/useAuth"
import DropdownMenu from "@/components/util/menu/DropdownMenu"
import logoutRedirect from "@/helpers/logout"
import {
  Button,
  Divider,
  Link as BaseLink,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem
} from "@material-ui/core"
import AccountCircle from "@material-ui/icons/AccountCircle"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import PolicyIcon from "@material-ui/icons/Policy"
import SettingsIcon from "@material-ui/icons/Settings"
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useLocation } from "react-router-dom"
import CustomMenuHelpLink from "../common/shared/CustomMenuHelpLink"

const useStyles = makeStyles(theme => ({
  userName: {
    fontSize: 12,
    paddingLeft: theme.spacing(1)
  }
}))

export default function ProfileMenu() {
  // const { t, i18n } = useTranslation()
  const { t } = useTranslation()
  let location = useLocation()
  const { user, logout } = useAuth()
  const classes = useStyles()
  const history = useHistory()

  const handleLogout = async () => {
    await logout()
    logoutRedirect({ history: history })
  }

  // const lang = i18n.language === "jp" ? "ja" : i18n.language

  return (
    <PopupState variant="popover" popupId="reports">
      {popupState => (
        <React.Fragment>
          <Button color="inherit" {...bindTrigger(popupState)}>
            <AccountCircle />
            <span className={classes.userName}>{user?.name}</span>
            <ArrowDropDownIcon />
          </Button>
          <DropdownMenu {...bindMenu(popupState)}>
            {user && user.organisation && (
              <MenuItem
                onClick={popupState.close}
                component={Link}
                to={{ pathname: "/settings", state: { from: location } }}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t("Settings")} />
              </MenuItem>
            )}
            <CustomMenuHelpLink />
            <Divider />
            <MenuItem
              component={BaseLink}
              color="inherit"
              target="_blank"
              href={"/privacy"}
            >
              <ListItemIcon>
                <PolicyIcon />
              </ListItemIcon>
              <ListItemText primary={t("privacyPolicy")} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={t("Logout")} />
            </MenuItem>
          </DropdownMenu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
