import AuthContext from "@/components/auth/AuthContext"
import { priorMonthDate } from "@/helpers/dateHandler"
import moment from "moment"
import { useContext } from "react"

const MISSING_CONTEXT_ERROR: Error = new Error(
  "usePreferences may only be called within an <Auth /> context."
)

// const MISSING_PREFERENCES_ERROR: Error = new Error(
//   "usePreferences is missing preferences."
// )

export default function usePreferences(isDaily = false) {
  const context = useContext(AuthContext)

  if (!context) {
    throw MISSING_CONTEXT_ERROR
  }

  if (!context.preferences) {
    // eslint-disable-next-line
    console.warn("No preferences found, resetting")

    // HACK: resets if no prefs found, should find source of problem
    context.preferences = {
      asOfDate: "",
      assetId: "",
      assetType: "",
      useLatestAsOfDate: true
    }
  }

  let asOfDate = context.preferences.asOfDate
  if (context.preferences.useLatestAsOfDate) {
    const today = moment()
    if (isDaily) asOfDate = today.format("YYYY-MM-DD")
    else asOfDate = priorMonthDate(today)
  }

  return {
    ...context.preferences,
    asOfDate,
    setPreferences: context.setFormPreferences,
    preferences: context.preferences
  }
}
