import {
  MenuItem,
  NoSsr,
  Paper,
  TextField,
  Typography
} from "@material-ui/core"
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles"
import { getIn } from "formik"
import React, { ReactNode, RefObject } from "react"
import BaseAsyncSelect from "react-select/async"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: "fixed",
    zIndex: 1,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    minWidth: theme.spacing(41)
  },
  divider: {
    height: theme.spacing(2)
  }
}))

interface SelectProps {
  classes: {
    noOptionsMessage?: string
    placeholder?: string
    singleValue?: string
    valueContainer?: string
    paper?: string
  }
}

type NoOptionsMessageProps = {
  children: ReactNode
  innerProps?: object
  selectProps: SelectProps
}

function NoOptionsMessage(props: NoOptionsMessageProps) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }: any) {
  return <div ref={inputRef} {...props} />
}

type ControlProps = {
  children: ReactNode
  innerProps: object
  innerRef: RefObject<any>
  selectProps: {
    classes: {
      input?: string
    }
    TextFieldProps: object
  }
}

function Control(props: ControlProps) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  )
}

type OptionProps = {
  children: ReactNode
  innerProps: object
  innerRef: RefObject<any>
  isFocused: boolean
  isSelected: boolean
}

function Option(props: OptionProps) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

type PlaceholderProps = {
  children: ReactNode
  innerProps?: object
  selectProps: SelectProps
}

function Placeholder(props: PlaceholderProps) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

type SingleValueProps = {
  children: ReactNode
  innerProps?: object
  selectProps: SelectProps
}

function SingleValue(props: SingleValueProps) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

type ValueContainerProps = {
  children: ReactNode
  selectProps: SelectProps
}

function ValueContainer(props: ValueContainerProps) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

type MenuProps = {
  children: ReactNode
  innerProps?: object
  selectProps: SelectProps
}

function Menu(props: MenuProps) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
}

export default function AsyncSelect({
  label,
  placeholder,
  loadOptions,
  field: { onChange, value, ...field },
  form: { touched, errors, status, setStatus },
  ...others
}) {
  const classes = useStyles()
  const theme = useTheme()

  //const { placeholder, value, onChange, ...others } = props

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  }

  const errorText =
    getIn(errors, field.name) || (status && getIn(status, field.name))

  const hasError =
    (getIn(touched, field.name) && errorText !== undefined) ||
    (status && getIn(status, field.name) !== undefined)

  const handleChange = event => {
    if (status) {
      delete status[field.name]

      setStatus(status)
    }

    onChange({ target: { name: field.name, value: event } })
  }

  return (
    <div className={classes.root}>
      <NoSsr>
        <BaseAsyncSelect
          classes={classes}
          styles={selectStyles}
          inputId="react-select-single"
          TextFieldProps={{
            label,
            InputLabelProps: {
              htmlFor: "react-select-single",
              shrink: true
            },
            error: hasError,
            helperText: hasError ? errorText : "",
            ...others
          }}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          components={components}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </NoSsr>
    </div>
  )
}
