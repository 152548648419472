import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import homePageStyles from "./homePageStyles"

const Testimonials = () => {
  const { t } = useTranslation()
  const classes = homePageStyles()

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        container
        alignItems="flex-start"
        alignContent="flex-start"
        spacing={3}
        xs={12}
        md={7}
        xl={5}
      >
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.businessSectionHeader}>
            {t("testimonials.header")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.businessSectionText}>
            {t("testimonials.bn")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.businessSectionText}>
            {t("testimonials.Benesse Group Pension Fund")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Testimonials
