/* eslint-disable import/no-anonymous-default-export */
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { SharedProps, useSnackbar } from "notistack"
import React from "react"

export default () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const action = key => (
    <IconButton onClick={() => closeSnackbar(key)}>
      <CloseIcon />
    </IconButton>
  )

  const addSnackbar = (message, variant: SharedProps["variant"] = "success") =>
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: "top", horizontal: "center" },
      variant,
      autoHideDuration: 5000, //default - They have close buttons so there is no reason to not use the default
      action
    })

  // same as regular snackbar but won't go away till the user clicks on it - use for errors that user needs to acknowledge
  const addPersistedSnackbar = (
    message,
    variant: SharedProps["variant"] = "success"
  ) =>
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: "top", horizontal: "center" },
      variant,
      persist: true,
      action
    })

  return { addSnackbar, addPersistedSnackbar }
}
