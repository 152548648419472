import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import homePageStyles from "./homePageStyles"

const Awards = ({ imgPath }: { imgPath: string }) => {
  const { t } = useTranslation()
  const classes = homePageStyles()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.businessSection}
    >
      <Grid
        item
        container
        alignItems="center"
        alignContent="center"
        xs={12}
        md={3}
        xl={2}
      >
        <Typography variant="h4" className={classes.businessSectionHeader}>
          {t("awards.header")}
        </Typography>
        <Typography variant="h6" className={classes.businessSectionText}>
          {t("awards.message")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={8}
        md={4}
        xl={3}
        justifyContent="flex-end"
        className={classes.businessSectionText}
      >
        <a
          href="https://4f-otmcbldg.tokyo/finopitch2022-award-winner/#:~:text=MITSUBISHI,%E9%81%8B%E7%94%A8%E8%B3%87%E7%94%A3%E3%82%92%E5%8F%AF%E8%A6%96%E5%8C%96%E3%81%99%E3%82%8B"
          target="_blank"
          rel="noreferrer"
          className={classes.businessSectionImage}
        >
          <img
            src={imgPath + "FinoPitch2022AwardWinner.png"}
            className={classes.businessSectionImage}
            alt="Finopitch 2022 Award Winner"
          />
        </a>
      </Grid>
    </Grid>
  )
}

export default Awards
