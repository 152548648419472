import ResetForm from "@/components/auth/ResetForm"
import useAuth from "@/components/auth/useAuth"
import { Card, CardContent, Container, makeStyles } from "@material-ui/core"
import React from "react"
import { Redirect } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(16)
  }
}))

export default function Reset() {
  const classes = useStyles()
  const { token } = useAuth()

  if (token) return <Redirect to="/" />

  return (
    <Container maxWidth="xs">
      <Card className={classes.card}>
        <CardContent>
          <ResetForm />
        </CardContent>
      </Card>
    </Container>
  )
}
