import emptyOption from "@/helpers/forms/emptyOption"
import { TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { FieldInputProps, FormikProps } from "formik"
import React from "react"

type Options = { label: string; value: string }

interface Props {
  options: Options[]
  label: string
  field: FieldInputProps<any>
  form: FormikProps<any>
  inputProps: object
  margin?: "normal"
}

const AutoComplete: React.FC<Props> = ({
  options,
  label,
  field: { value, onChange, ...field },
  form: { touched, errors, setFieldValue, status, setStatus }
}: Props) => {
  const hasOptionShape = options?.[0]?.value !== undefined
  const errorText = errors?.[field.name]
  const hasError = errorText !== undefined
  const statusText = status && status?.[field.name]
  const hasStatus = status && status?.[field.name] !== undefined

  const handleChange = (event, nV) => {
    setFieldValue(field.name, nV ?? (hasOptionShape ? emptyOption : ""))
    if (status) {
      delete status[field.name]

      setStatus(status)
    }
  }

  return (
    <>
      <Autocomplete
        clearOnBlur
        clearOnEscape
        onChange={handleChange}
        value={value}
        options={options}
        getOptionLabel={o => o.label}
        getOptionSelected={(op, v) => op.value === v.value}
        renderInput={params => (
          <TextField
            error={hasError}
            label={label}
            value={value}
            variant="outlined"
            margin="normal"
            {...params}
          />
        )}
      />
      <Typography color="error">
        {hasError ? errorText?.["value"] ?? errorText ?? "" : ""}
      </Typography>
      <Typography color="error">
        {hasStatus ? statusText?.["value"] ?? statusText ?? "" : ""}
      </Typography>
    </>
  )
}

export default AutoComplete
