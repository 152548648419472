import { Button } from "@material-ui/core"
import { connect } from "formik"
import React from "react"

function Submit(props) {
  const { label, formik, onlyValid, ...other } = props
  const { isSubmitting } = formik

  const disabled = isSubmitting || (onlyValid && !formik.isValid)

  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={disabled}
      data-testid="Submit"
      {...other}
    >
      {label}
    </Button>
  )
}

export default connect(Submit)
