import FormDialog from "@/components/util/dialog/FormDialog"
import { TextField } from "@/components/util/form/fields"
import {
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from "@material-ui/core"
import BaseTextField from "@material-ui/core/TextField"
import EditIcon from "@material-ui/icons/Edit"
import { Field, FieldInputProps, FormikProps, getIn } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

interface Option {}

export type SplitTextFieldProps = {
  label: string
  field: FieldInputProps<any>
  form: FormikProps<any>
  isOpen: boolean
  onClose: () => void
  splitOptions: Option[]
  filterForm: () => void
  canSplit: boolean
}

export type Splits = {
  [key: string]: number
}

export const valueSumCheck = ({
  valueSum,
  sigDigs,
  obj
}: {
  valueSum: string
  sigDigs: number
  obj: Splits
}) =>
  Object.keys(obj)
    .reduce((total, key) => total + +(obj[key] ? obj[key] : 0), 0)
    .toFixed(sigDigs) === valueSum

export default function SplitTextField({
  label,
  field: { onChange, value, ...field },
  form: { touched, errors, status },
  isOpen,
  onClose,
  splitOptions,
  filterForm,
  canSplit,
  ...other
}: SplitTextFieldProps) {
  let hasSubmitted = false
  const innerSchema = {}

  Object.keys(splitOptions).forEach(
    key =>
      (innerSchema[key] = Yup.number()
        .min(0, "Target must be between 0 and 100")
        .max(100, "Target must be between 0 and 100")
        .nullable())
  )

  const SplitSchema = Yup.object().shape({
    options: Yup.object()
      .shape(innerSchema)
      .test("isValidSplit", "A valid split must total to 100.", value => {
        if (!value) return false

        return valueSumCheck({
          valueSum: "100.0000",
          sigDigs: 4,
          obj: value as Splits
        })
      })
  })
  const [isSplitDialogOpen, setIsSplitDialogOpen] = useState(false)
  const { t } = useTranslation()
  const errorText =
    getIn(errors, field.name) || (status && getIn(status, field.name))

  const hasError =
    (getIn(touched, field.name) && errorText !== undefined) ||
    (status && getIn(status, field.name) !== undefined)

  // const handleChange = event => {
  //   if (status) {
  //     delete status[field.name]

  //     setStatus(status)
  //   }

  //   onChange(event)
  // }

  const createSplitLabel = split => {
    if (split)
      return Object.keys(split).reduce((splitString, item) => {
        splitString += `${item}:${split[item]}%`
        return splitString
      }, " ")
    return ""
  }

  const handleSubmission = ({ options: values }) => {
    onChange({
      target: {
        name: field.name,
        value: Object.keys(values).reduce((options, key) => {
          if (values[key] > 0) {
            options[key] = values[key]
          }
          return options
        }, {})
      }
    })
    setIsSplitDialogOpen(false)
  }

  return (
    <>
      <BaseTextField
        label={label}
        error={hasError}
        helperText={hasError ? errorText : ""}
        value={createSplitLabel(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                title={t("Edit Split")}
                size="small"
                disabled={!canSplit}
                onClick={() => {
                  filterForm()
                  setIsSplitDialogOpen(true)
                }}
              >
                <EditIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...field}
        {...other}
      />
      <FormDialog
        open={isSplitDialogOpen}
        onClose={() => setIsSplitDialogOpen(false)}
        title={t("Edit Split Category")}
        initialValues={{ options: splitOptions }} //out of box supprot requires a key value to be mapped
        onClickLabel={t("Ok")}
        validationSchema={SplitSchema}
        onSubmit={handleSubmission}
        key={field.name}
      >
        <Grid container spacing={2}>
          {Object.keys(splitOptions).map((key, index) => (
            <>
              <Grid item xs={6} container alignItems="center">
                <Grid item>
                  <FormLabel>{key}</FormLabel>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`options.${key}`}
                  component={TextField}
                  margin="normal"
                  label=""
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </>
          ))}
          <Field
            name="options"
            render={({ form }) => {
              // Hack to ensure that errors only display once form has been submitted
              if (form.isSubmitting === true) {
                hasSubmitted = true
              }
              const error = getIn(form.errors, "options")
              return error && typeof error === "string" && hasSubmitted ? (
                <Grid item>
                  <Typography color="error">{error}</Typography>
                </Grid>
              ) : null
            }}
          />
        </Grid>
      </FormDialog>
    </>
  )
}
