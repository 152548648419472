import { makeStyles } from "@material-ui/core"

const homePageStyles = makeStyles(theme => ({
  businessSectionHeader: {
    fontWeight: "bold",
    color: "dimgray",
    marginBottom: theme.spacing(3),
    fontFamily: "Nunito Sans, Roboto"
  },
  businessSectionText: {
    color: "dimgray",
    fontFamily: "Nunito Sans, Roboto",
    padding: "10px"
  },
  businessSectionImage: {
    height: "16em",
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto"
    }
  },
  businessSection: {
    marginBottom: theme.spacing(4),
    padding: "1em"
  }
}))

export default homePageStyles
