import LoginForm from "@/components/auth/LoginForm"
import useAuth from "@/components/auth/useAuth"
import {
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles
} from "@material-ui/core"
import Cookies from "js-cookie"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, Redirect } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(16)
  },
  cardContent: {
    margin: theme.spacing(3)
  },
  forgot: {
    textDecoration: "none",
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2)
  }
}))

export default function Login() {
  const classes = useStyles()
  const { token, login_with_token } = useAuth()
  const { t } = useTranslation()

  //okta callback-hell bridge
  let cookie_token = Cookies.get("user_token")
  if (cookie_token) {
    login_with_token(token ?? cookie_token)

    // must be exact match, otherwise user will stay "logged on" till session expires
    //note: this will not work on localhost
    Cookies.remove("user_token", { path: "/", domain: ".visual-alpha.com" })
    // enable on localhost to test if needed
    // Cookies.remove( "user_token", { path: '/', domain: 'localhost' } );
    return <Redirect to="/" />
  }

  // user authenticated
  if (token) return <Redirect to="/" />

  // regular login -> these two should be merged
  //    okta redirect happens inside here on response
  return (
    <Container maxWidth="xs">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <LoginForm />
        </CardContent>
      </Card>
      <Grid container>
        <Link to="/forgotpassword" className={classes.forgot}>
          {t("Forgot your password?")}
        </Link>
      </Grid>
    </Container>
  )
}
