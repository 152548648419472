import { makeStyles, MenuItem, Typography } from "@material-ui/core"
import { uniqueId } from "lodash"
import React from "react"
import TextField from "./TextField"

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.dark,
    fontWeight: "bold"
  }
}))

export default function Select(props) {
  const classes = useStyles()

  const {
    options,
    isClosed,
    displayEmpty,
    shrink,
    useValueAsLabel,
    selectProps = {},
    ...other
  } = props

  const select = {
    displayEmpty,
    SelectDisplayProps: { "data-testid": props.field.name + "-container" },
    ...selectProps
  }
  const input = { shrink }

  return (
    <TextField select SelectProps={select} InputLabelProps={input} {...other}>
      {options.map(item => (
        <MenuItem
          key={uniqueId(item.value)}
          value={item.value}
          disabled={item.header || item.error !== undefined}
          className={item.error ? classes.error : ""}
        >
          {isClosed === true
            ? item.selectedLabel ?? (useValueAsLabel ? item.value : item.label)
            : useValueAsLabel
            ? item.value
            : item.label}

          {item.error ? <Typography>-- Error: {item.error}</Typography> : <></>}
        </MenuItem>
      ))}
    </TextField>
  )
}
