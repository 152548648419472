import { makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  header: {
    textAlign: "center",
    margin: theme.spacing(3)
  },
  end: {
    textAlign: "right",
    margin: theme.spacing(3)
  }
}))

export default function TermsText() {
  const classes = useStyles()

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        Visual Alphaクラウド・サービス
        <br />
        利用約款
      </Typography>
      <Typography variant="h6">第1条（目的)</Typography>
      <p>
        Visual Alphaクラウド・サービス利用約款（以下「本約款」という）は、Visual
        Alpha株式会社（以下「当社」と いう）が提供する「Visual
        Alphaクラウドサービス」(以下「本サービス」という)の利用を目的とする契約（以下「利用契約」という）の内容を定めるものであり、本サービスの利用登録をした者（以下「利用者」という）に適用されます。
      </p>
      <Typography variant="h6">第2条（本サービス）</Typography>
      <p>
        1.
        本サービスは、当社が提供するクラウド上の投資運用報告データの集計と分析サービス、その他の付加サービスのことをいいます。
      </p>
      <p>
        2.
        本サービス内容の細目と特記事項については別途利用契約或いは当社ウェブサイト等上に掲載されるサービス・規約・通知等に定めるものとします。
      </p>
      <p>
        3.
        本サービスを利用する場合、利用者は本約款の全ての条件に同意されたものとみなされます。
      </p>
      <Typography variant="h6">第3条（本約款の変更手続）</Typography>
      <p>
        当社は、利用者の承諾を得ることなく本約款の全部または一部を変更できるものとします。当社は、本約款を変更した場合には、利用者に対し、当社が適当と認める方法により当該変更内容を通知するものとし、内容に特段の定めのない限り通知内容を掲載または発信した時点から変更した本約款の効力が生じるものとします。
      </p>
      <Typography variant="h6">第4条（利用契約の締結）</Typography>
      <p>
        本サービスの利用契約の締結は利用者と当社が利用契約書を合意し、捺印することより行うものとします。
      </p>
      <Typography variant="h6">第5条（無料サービス）</Typography>
      <p>
        1.
        本サービスの一部は利用者に無料で提供しています（以下「無料サービス」という）。
      </p>
      <p>
        2.
        利用者が無料サービスのみ利用する場合、利用契約を締結する必要はありません。
      </p>
      <Typography variant="h6">第6条（利用料金）</Typography>
      <p>1. 本サービスの利用料金の発生は、それぞれ以下のとおりとします。</p>
      <p>
        (1)
        「月額」とされているものについては、月の初日に当該月の利用料金が発生するものとします。
      </p>
      <p>
        (2)
        「年額」とされているものについては、実施開始日に応当する日に当該年の利用料金が発生するものとします。
      </p>
      <p>
        (3)
        「従量」とされているものについては、利用量に応じて発生するものとします。
      </p>
      <p>
        2.
        利用料金のうち、料金種別が「従量」または「月額」とされているものについては、サービス実施開始日またはサービス実施終了日が料金月の途中であっても日割計算しません。また、料金種別が「月額」とされているものについては、料金月の途中に数量が変更された場合、当該料金月における最大の数量に対する月額利用料金の全額が当該料金月の利用料金として適用されるものとします。
      </p>
      <p>
        3.
        利用料金のうち、料金種別が「年額」とされているものについては、サービス実施期間満了以前に終了した場合でも、当社の責に帰すべき事由がある場合を除き、契約者に対し返還されません。また、サービス実施期間の途中に数量を変更することはできないものとします。
      </p>
      <p>
        4.
        本サービスの利用料金にかかる消費税および地方消費税（以下「消費税等」という）相当額は、前項に基づき算出される、利用契約全体で合算された利用料金に対して算定されるものとします。なお、消費税等相当額の算定の際の税率は、当該算定時に税法上有効な税率とします。
      </p>
      <Typography variant="h6">第7条（支払方法）</Typography>
      <p>
        1.
        当社は利用料金を利用者に請求するものとし、利用者は、当該請求金額を請求日の翌月末までに当社指定の銀行口座に振込むものとします。
      </p>
      <p>2. 前項にかかる振込手数料は、利用者の負担とします。</p>
      <Typography variant="h6">第8条（遅延損害金）</Typography>
      <p>
        利用者は、利用料金等の支払を遅延した場合、支払期限の翌日から完済に至るまで、年
        14.6％の遅延損害金を当社に支払うものとします。
      </p>
      <Typography variant="h6">第9条（認証情報）</Typography>
      <p>
        1.
        利用者は、当社から交付された本サービスを利用するためのユーザーID、パスワード、API
        キー等（以下あわせて「認証情報」という）を、有償・無償を問わず第三者に譲渡または貸与することはできません。
      </p>
      <p>
        2.
        利用者は、自己の責任において認証情報を管理、使用するものとします。万一、利用者による認証情報の管理または利用に関連し第三者が不正ないし不適切に使用等をしたことが原因で、利用者に不利益または損害が生じても、当社は一切責任を負わないものとします。
        また、利用者による認証情報の不正使用や管理不十分により当社または第三者に損害が生じた場合には、利用者が責任を負います。
      </p>
      <p>
        3.
        利用者は、認証情報を失念した場合または盗用された場合は、速やかに当社に連絡し、その指示に従うものとします。
      </p>
      <Typography variant="h6">第10条（変更の届出）</Typography>
      <p>
        利用者は、申込内容に変更があった場合、所定の様式により、速やかに当社に対して届出るものとします。
      </p>
      <Typography variant="h6">第11条（権利の譲渡）</Typography>
      <p>
        利用者は、当社の事前の書面による承諾なしに利用契約の地位を第三者に承継させ、あるいは利用契約から生じる権利義務の全部または一部を第三者に譲渡しもしくは引き受けさせ、または担保に供してはならないものとします。
      </p>
      <Typography variant="h6">第12条（禁止事項）</Typography>
      <p>
        利用者は、本サービスを利用するにあたり、次の各号に該当する行為を行ってはならないものとします。
      </p>
      <p>
        (1)
        当社もしくは第三者の著作権・商標権等の知的財産権、肖像権、プライバシーの権利、名誉、その他の財産権や人格権等の権利を侵害する行為、または侵害するおそれのある行為
      </p>
      <p>
        (2)
        当社の許諾を得ずに、当社が提供する情報等の翻訳、編集、改変等を行い、第三者に使用させ、又は公開する行為、又は当社若しくは当社にライセンスを許諾している者のプログラムの逆アセンブル、逆コンパイル、リバースエンジニアリング等
      </p>
      <p>
        (3)
        当社のネットワークやインターネット網、それらに接続されたサーバー設備等に不正にアクセスする行為、当社の設備に蓄積された情報を不正に書換え若しくは消去する行為、コンピューター・ウィルスその他の有害なプログラムを含むデータを送信する行為、その他当社に損害を与える行為
      </p>
      <p>(4) 他の利用者や第三者に対する詐欺又は脅迫行為</p>
      <p>(5) 公序良俗に反する行為およびそのおそれのある行為</p>
      <p>
        (6)
        法令に違反する行為、法令違反を助長する行為またはそれらのおそれのある行為
      </p>
      <p>
        (7)
        本サービス及び本サービスを通じてアクセスするネットワークまたはシステム等に過度な負荷をかける行為
      </p>
      <p>(8) 他の利用者または第三者になりすます行為</p>
      <p>(9) 虚偽の情報を届出る行為</p>
      <p>(10) 認証情報を不正に利用する行為</p>
      <p>
        (11) その他、当社が本サービスの利用者として相応しくないと判断する行為
      </p>
      <Typography variant="h6">第13条（損害賠償）</Typography>
      <p>
        利用者またはその代理人、使用人その他利用者の関係者が本約款に違反する行為をなし、当社に損害を与えた場合、利用者は当社に対し、その損害を賠償しなければなりません。
      </p>
      <Typography variant="h6">第14条（秘密情報の取扱い）</Typography>
      <p>
        1.
        利用者および当社は、相手方から本サービスに関連してそれぞれ提供を受けた技術上または営業その他業務上の情報のうち、相手方が特に秘密である旨指定した情報（以下「秘密情報」という）を被提供者の書面による事前の承諾なしに、第三者に漏洩してはならないものとします。なお、利用者が当社に秘密情報を口頭で提供する場合は、その際に秘密であることを当社に通知するものとします。ただし秘密情報には、(i)提供を受けた時点で公
        知公用であった情報、(ii)提供を受けた時点で既に所有していたもの、(iii)提供を受けた後に、提供された情報によることなく独自に開発したもの、(iv)提供を受けた後に自らの責によらずして公知公用となった情報、(v)正当な権利を有する第三者から合法的に取得したものは含まれません。本項にかかわらず、被提供者が裁判所、弁護士や公認会計士その他公的機関から秘密情報の開示の命令や要請がある場合、または法的に秘密情報を開示する義務を負う場合には秘密情報を必要かつ合理的な範囲で開示できるものとします。
      </p>
      <p>
        2. 当社は、本サービスの提供に伴い取り扱う通信の秘密を電気通信事業法第4
        条に基づき保護し、本サービスの円滑な提供を確保するために必要な範囲でのみ使用または保存します。
      </p>
      <p>
        3. 当社は、刑事訴訟法第 218
        条（令状による差押え・捜索・検証）その他同法の定めに基づく強制処分が行われた場合には、当該法令および令状に定める範囲で前項の守秘義務を負わないものとします。
      </p>
      <p>
        4.
        利用者と当社の間すでに別途で機密保持契約を締結してある場合、本条の内容は機密保持契約に準じます。
      </p>
      <Typography variant="h6">第15条（利用者固有情報）</Typography>
      <p>
        1.
        当社は、利用者が本サービスに自ら登録・入力した、利用者固有の情報であってアクセス制御機能が施されているもの（以下「利用者固有情報」という）を、利用者の同意なく参照、閲覧等して利用しません。
      </p>
      <p>
        2.
        前項の定めにかかわらず、当社は、次の各号のいずれかに該当する場合には、利用者固有情報を、正当な範囲で参照、閲覧（当該各号において定める場合には第三者に開示することを含む）することがあるものとします。なお、当社は、次の各号のいずれかに該当することにより参照・閲覧された利用者固有情報を、当該各号の定めに基づく参照・閲覧の目的以外の目的に利用しないものとします。
      </p>
      <p>
        (1) 刑事訴訟法第 218
        条その他同法もしくは犯罪捜査のための通信傍受に関する法律の定めに基づく強制の処分その他裁判所の命令もしくは法令に基づく強制的な処分が行われた場合において、当該処分の範囲で開示する場合
      </p>
      <p>
        (2)
        特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律第4
        条に基づく開示請求の要件が充足された場合において、当該開示請求の範囲で開示する場合
      </p>
      <p>
        (3)
        生命、身体または財産の保護のために必要があると当社が判断した場合において、当該保護のために必要な範囲で利用、開示する場合
      </p>
      <p>
        (4)
        当社が本サービスを運営するために必要な範囲（利用料金の算定、設備の維持等）において利用者固有情報を参照する場合
      </p>
      <Typography variant="h6">第16条（データの取扱い）</Typography>
      <p>
        1.
        当社は、利用者による本サービスの利用に関して保存された閲覧記録等のデータ（以下「データ」という）を前条に従って取扱うものとします。
      </p>
      <p>
        2.
        前項にかかわらず、当社は、本サービスを提供し、または当社のサービスの改善を図るために必要な範囲でデータを使用し、複製・保存することができるものとします。ただし、これによって当社にデータをバックアップする義務が生じるものではなく、データの消失、
        バックアップをしなかったこと、バックアップしたデータを保存していなかったこと等について、当社は一切責任を負わないものとします。
      </p>
      <p>
        3.
        当社は、解除、期間満了等により利用契約が終了したときは、本約款に別段の定めがない限り、データを全て消去できるものとします。
      </p>
      <Typography variant="h6">
        第17条（当社による本サービス提供の中止）
      </Typography>
      <p>
        1.
        当社は、次に掲げる事由がある場合は、本サービスの提供を中止することができます。
      </p>
      <p>
        (1) 電気通信事業法第8
        条の規定に基づき、天災その他の非常事態が発生し、もしくはそのおそれがあり、公共の利益のために緊急を要する通信を優先させる必要がある場合
      </p>
      <p>
        (2)
        基礎的電気通信役務を提供する電気通信事業者等が、電気通信サービスを中止した場合
      </p>
      <p>
        (3) サイバーテロなど外部からの攻撃または妨害などの事態の対応をする場合
      </p>
      <p>
        (4)
        利用者の本サービス使用環境、インターネット環境の不具合、予期せぬ要因によるシステム負荷の増大、当社利用のクラウドサービスに支障がある等、その他当社の責に帰さない事由により本サービスの提供を中止せざるを得ない場合
      </p>
      <p>(5) 利用者のセキュリティを確保する必要が生じた場合</p>
      <p>
        (6)
        当社の本サービス用のハードウェア並びにソフトウェア及びデータの保守、点検、改良または拡張などの実施するため必要がある場合
      </p>
      <p>(7) その他前各号に準じ当社が必要と判断した場合</p>
      <p>
        2.
        当社は、本サービスの提供を中止する場合には、利用者に対して事前に、その旨ならびに理由および期間を通知します。ただし、緊急を要する場合はこの限りではありません。
      </p>
      <p>
        3. 当社は、第 1
        項に基づき本サービスの提供を中止した場合に利用者が被った損害について賠償の責任を負いません。また、本サービスの中止に伴う返金は行いません。
      </p>
      <Typography variant="h6">
        第18条（利用者の事由による本サービスの提供の一時停止）
      </Typography>
      <p>
        1.
        当社は、利用者が次に掲げる事由に該当する場合には、当該利用者に対する本サービスの提供を一時停止することがあります。
      </p>
      <p>(1) 利用者が利用料金の支払いを遅滞した場合</p>
      <p>
        (2)
        利用者の行為が当社の電気通信設備に支障を及ぼし、またはそのおそれがあり、その結果、当社の業務遂行に支障が生じると当社が認めた場合
      </p>
      <p>(3) 利用者が第 12条に定める禁止事項を行った場合</p>
      <p>(4) 利用者の本サービスの使用方法に誤りがあった場合</p>
      <p>
        2.
        当社は、利用者に対して本サービスの提供を一時停止する場合には、当該利用者に対して事前に、その旨ならびに理由および期間を通知します。ただし、緊急を要する場合はこの限りではありません。
      </p>
      <p>
        3. 当社は、第 1
        項に基づき本サービスの提供を一時停止した場合に利用者が被った損害について賠償の責任を負いません。また、本サービスの一時停止に伴う返金は行いません。
      </p>
      <Typography variant="h6">第19条（本サービスの終了）</Typography>
      <p>
        1.
        当社は、当社または利用者が次の各号に掲げる事由に該当する場合には、利用者に対し何らの催告をすることなく直ちに本サービスの提供を終了することができます。
      </p>
      <p>(1) 利用者が第 12 条に定める禁止事項を行った場合</p>
      <p>
        (2)
        当社または利用者に支払の停止があった場合、または仮差押、差押、競売、破産、民事再生手続開始、会社更生手続開始、特別清算開始の申立があった場合、またはこれらのおそれがある場合
      </p>
      <p>
        (3)
        当社または利用者が自ら振出しまたは引受けた手形または小切手につき不渡処分を受けた場合
      </p>
      <p>(4) 当社または利用者が公租公課の滞納処分を受けた場合</p>
      <p>
        (5)
        その他前各号に準ずる本サービスの提供を継続しがたい重大な事由が発生した場合
      </p>
      <p>
        2.
        利用者は、書面により本サービスの利用の終了を通知する場合は、当社に対し利用期間満了日が属する月の月末日3か月前までに所定の様式により通知することにより、当該終了日付で本サービスの利用を終了することができます
      </p>
      <p>
        3.
        当社の責に帰すべき事由により本サービスの提供が終了した場合を除き、利用者が、前項に基づきサービスの利用を終了しても、当社は既に受領した利用料金を一切利用者に返還しません。
      </p>
      <Typography variant="h6">第20条（反社会的勢力との取引排除）</Typography>
      <p>
        1.
        当社は、利用者が次の各号のいずれかに該当した場合は、当該利用者に何らの通知、催告を要しないで、直ちに本サービスの全部または一部の提供を終了することができるものとします。
      </p>
      <p>
        (1)
        暴力団、暴力団員、暴力団関係企業または団体、暴力団関係者、いわゆる総会屋、その他の反社会的勢力（以下、「反社会的勢力」という）であるとき、または反社会的勢力であったことが認められるとき
      </p>
      <p>
        (2)
        反社会的勢力との密接な交際や資金提供を行うなど、反社会的勢力の活動を助長する行為を行ったとき
      </p>
      <p>
        (3)
        自らあるいは第三者を利用して相手方に対し暴力的行為、威迫的行為または詐術行為をしたとき
      </p>
      <p>
        (4)
        自らあるいは第三者を利用して相手方に対し不当に名誉、信用を毀損したとき
      </p>
      <p>
        (5)
        自らあるいは第三者を利用して相手方に対し業務を妨害し、または妨害するおそれのある行為を行ったとき
      </p>
      <p>
        (6)
        自らまたは第三者を利用して相手方に対し自らが反社会的勢力である旨を伝えたとき
      </p>
      <p>
        2.
        当社は、利用者が次の各号に該当する場合も前項の規定が適用され、同様に本サービスの提供を終了ことができるものとします。
      </p>
      <p>
        (1)
        利用者の役員、本サービスの契約者、本サービスの履行に関する責任者および実質的な経営権を有する者が、前1項各号のいずれかに該当するとき
      </p>
      <p>
        (2)
        親会社または子会社（いずれも会社法の定義による。）が、本条第1項各号のいずれかに該当するとき
      </p>
      <p>
        3.
        当社は、本条第1項または本条第2項の規定により本サービスの全部または一部の提供を終了した場合には、当該利用者に損害が生じても、これを一切賠償しないものとします。
      </p>
      <p>
        4.
        当社様は、利用者が本条第1項または本条第2項に該当したことにより、自身が損害を被った場合には利用者に対し、その損害の賠償を請求することができるものとします。
      </p>
      <Typography variant="h6">第21条（料金の減額）</Typography>
      <p>
        当社の責に帰すべき事由により、利用者が本サービスを全く利用できない状態に陥った場
        合、当社は、当社が当該利用者における利用不能を知った時刻から起算して 24
        時間以上その状態が継続した場合に限り、利用料金を以下のとおり減額します。ただし、料金種別が「従量制」の場合には、減額しないものとします。
      </p>
      <p>
        (1) 料金種別が「月額」の場合：月額利用料金の 30 分の 1
        に利用不能の日数を乗じた額（円 未満切り捨て）
      </p>
      <p>
        (2)
        料金種別が「年額」の場合：年額利用料金の365分の1に利用不能の日数を乗じた額（円未満切り捨て）
      </p>
      <Typography variant="h6">第22条（免責）</Typography>
      <p>
        1.
        当社、当社取締役、役員及び従業員は、本約款で特に定める場合を除き、利用者が本サービスの利用したこと、または利用できなかったことを原因として生じるいかなる損害について、債務不履行責任、不法行為責任その他の法律上の責任を問わず賠償の責任を負わないものとします。ただし、利用者が本サービスの利用に関して当社の故意または重大な過失により損害を被った場合については、この限りではありません。いかなる場合であっても、損害賠償の累計総額は、債務不履行、法律上の瑕疵担保責任、不当利得、不法行為その他の請求原因のいかんにかかわらず、損害の事由が生じた時点からさかのぼって過去1か月間（年額の場合、過去１年間）に当該利用者から当社が受領した利用料金の金額を限度とします。
      </p>
      <p>
        2.
        当社は、付随的損害、特別損害、間接損害、懲罰的損害（利益、収入、利息、またはデータの破壊もしくは喪失、または、利用者の事業におけるその他の喪失または中止を含む）につき、責任を負わないものとします。
      </p>
      <p>
        3.
        当社は、利用者が本サービスを利用する結果、及び本サービスにより記録されたデータの完全性、網羅性、正確性、確実性、有用性などに関して、本約款に定められた事項を除き何ら保証しません。
      </p>
      <p>
        4.
        利用者が本サービスを利用するにあたって発生した第三者との紛争に関しては、利用者が自らその責任において解決するものとし、当社は一切責任を負いません。
      </p>
      <Typography variant="h6">第23条（知的財産権）</Typography>
      <p>
        本サービスに関する著作権、特許権、商標権その他一切の知的財産権は、当社又は当社がライセンスを受けた当該権利を有する第三者に帰属します。
      </p>
      <Typography variant="h6">第24条（準拠法）</Typography>
      <p>利用契約は、日本国法に準拠し、同法に従って解釈されるものとします。</p>
      <Typography variant="h6">第25条（合意管轄）</Typography>
      <p>
        利用契約に関し、訴訟の必要が生じた場合には、東京地方裁判所を専属的合意管轄裁判所とします。
      </p>
      <Typography variant="h6">第26条（協議）</Typography>
      <p>
        本約款に定めのない事項または疑義が生じた事項については、信義誠実の原則に従い当社と利用者が協議し、円満に解決を図るものとします。
      </p>
      <Typography className={classes.end}>以上</Typography>
    </>
  )
}
