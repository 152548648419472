import { Field, getIn } from "formik"
import React from "react"

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)

      return error && touch ? error : null
    }}
  />
)

export default ErrorMessage
