import ForgotForm from "@/components/auth/ForgotForm"
import useAuth from "@/components/auth/useAuth"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  makeStyles
} from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, Redirect } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(16)
  }
}))

export default function Forgot() {
  const classes = useStyles()
  const { token } = useAuth()
  const { t } = useTranslation()

  if (token) return <Redirect to="/" />

  return (
    <Container maxWidth="xs">
      <Card className={classes.card}>
        <CardContent>
          <ForgotForm />
        </CardContent>
        <CardActions>
          <Button size="small" component={Link} to="/login">
            {t("Login")}
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}
