import { format, isValid, parse, parseISO } from "date-fns"
import { TFunction } from "i18next"

export const DATE_FORMAT = "yyyy-MM-dd"
export const DATE_FORMAT_SLASHES = "yyyy/MM/dd"
export const DATE_FORMAT_SLASHES_MOMENT = "YYYY/MM/DD"
export const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm"

const dateStringToDate = (
  dateString: string,
  dateFormat = DATE_FORMAT
): Date => {
  return parse(dateString, dateFormat, new Date())
}

export const longDateFormatter = (d?: string | null) => {
  if (!d) {
    return "-"
  }
  return format(parseISO(d), DATE_TIME_FORMAT)
}

/**
 * Formats an ISO date string.
 *
 * If the date is invalid or `null`, a dash (`-`) will be returned.
 *
 * @param d The string to format
 */
export const formatDateString = (
  d: string | null,
  format_string: string = DATE_FORMAT
) => {
  if (d === null) {
    return "-"
  }

  const parsed = parseISO(d)
  if (isValid(parsed)) {
    return format(parsed, format_string)
  }
  return "-"
}

export const formatDateTimeString = (d: string | null) =>
  formatDateString(d, DATE_TIME_FORMAT)

/**
 * Checks if a date string is valid according to the specified format.
 *
 * @todo: Should support "unknown" format as well
 *
 * @param dateString The input date string to validate.
 * @param dateFormat The expected format of the date string.
 */
export const isValidDateString = (
  dateString: any,
  dateFormat = DATE_FORMAT
): boolean => {
  const date = dateStringToDate(dateString, dateFormat)
  return isValid(date)
}

export const toJSDate = (dateString: any, dateFormat?: string): Date | null => {
  if (!isValidDateString(dateString, dateFormat)) {
    return null
  }
  return dateStringToDate(dateString, dateFormat)
}
export const toDateString = (
  dateString: any,
  dateFormat = DATE_FORMAT
): string => {
  if (!isValidDateString(dateString, dateFormat)) {
    return "-"
  }
  return formatToDateString(
    dateStringToDate(dateString, dateFormat),
    dateFormat
  )
}
export const formatToDateString = (
  date: Date,
  dateFormat = DATE_FORMAT
): string => {
  return format(date, dateFormat)
}

export const deadlineFormatter = (deadline: number | null, t: TFunction) =>
  `${deadline} ${t("Business Days")}`
