import Auth from "@/components/auth/Auth"
import BasicErrorBoundary from "@/components/util/error/BasicErrorBoundary"
import GAListener from "@/components/util/tracking/GAListener"
import Forgot from "@/pages/auth/Forgot"
import Login from "@/pages/auth/Login"
import PasswordReset from "@/pages/auth/PasswordReset"
import Register from "@/pages/auth/Register"
import Reset from "@/pages/auth/Reset"
import WrongIP from "@/pages/auth/WrongIP"
import Privacy from "@/pages/policies/privacy/Privacy"
import Terms from "@/pages/policies/Terms"
import Contact from "@/pages/public/Contact"
import Home from "@/pages/public/Home"
import AppRoutes from "@/routes/App"
import RouteHome from "@/routes/RouteHome"
import { trackingId } from "@/services/config"
import { ApolloProvider } from "@apollo/client"
import { CssBaseline, ThemeProvider } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import { SnackbarProvider } from "notistack"
import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import client from "./client"
import "./style.css"

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        'input[type="number"]': {
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
          },
          "-moz-appearance": "textfield"
        }
      }
    }
  }
})
export default function App() {
  return (
    <BasicErrorBoundary>
      <ApolloProvider client={client}>
        <Auth>
          <BrowserRouter>
            <GAListener trackingId={trackingId}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <Switch>
                    <RouteHome
                      exact
                      path="/:language(en|jp|ja)?"
                      component={Home}
                    />
                    <RouteHome exact path="/register" component={Register} />
                    <RouteHome exact path="/login" component={Login} />
                    <RouteHome
                      exact
                      path="/forgotpassword"
                      component={Forgot}
                    />
                    <RouteHome exact path="/notallowedip" component={WrongIP} />
                    <RouteHome
                      exact
                      path="/passwordreset"
                      component={PasswordReset}
                    />
                    <RouteHome
                      exact
                      path="/passwordreset/:token"
                      component={Reset}
                    />
                    <RouteHome exact path="/privacy" component={Privacy} />
                    <RouteHome exact path="/terms" component={Terms} />
                    <RouteHome exact path="/contact" component={Contact} />
                    <Route component={AppRoutes} />
                  </Switch>
                </SnackbarProvider>
              </ThemeProvider>
            </GAListener>
          </BrowserRouter>
        </Auth>
      </ApolloProvider>
    </BasicErrorBoundary>
  )
}
