/* eslint-disable import/extensions */
import Loading from "@/components/util/Loading"
import profileFragment from "@/graphql/fragments/profileFragment"
import { usePersistedState } from "@/helpers/state"
import Unauthorized from "@/pages/error/Unauthorized"
import authenticationService from "@/services/authenticationService"
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery
} from "@apollo/client"
import jwt_decode from "jwt-decode"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import AuthContext, { Preferences, User } from "./AuthContext"

/* eslint-disable */
export const GET_AUTH_STATUS = gql`
  query AuthStatus {
    authStatus @client
    unauthorized @client
  }
`
/* eslint-enable */

const GET_PROFILE = gql`
  query ProfileAuth {
    me {
      id
      ...profileFragment
    }
  }
  ${profileFragment}
`

export const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferencesAuth($preferences: Mixed) {
    updateProfile(preferences: $preferences) {
      id
      ...profileFragment
    }
  }
  ${profileFragment}
`

type AuthProps = {}

interface Token {
  exp: number
}

const Auth: FunctionComponent<AuthProps> = props => {
  const { children } = props
  const [token, setToken] = usePersistedState<string | undefined>(
    "token",
    false
  )
  const [user, setUserState] = useState<User | null>(null)
  const [preferences, setPreferences] = usePersistedState<
    Preferences | undefined
  >("preferences", true)
  const [unauthorized, setUnauthorized] = useState(false)
  const { i18n } = useTranslation()
  const client = useApolloClient()

  const [loadProfile, { data: profileData }] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: "network-only"
  })
  const [updatePreferences] = useMutation(UPDATE_PREFERENCES)

  const setUser = (user: User | null) => {
    setUserState(user)

    if (user) {
      if (!preferences) {
        const { asOfDate, assetId, assetType, preferences } = user
        setPreferences({
          asOfDate: preferences?.asOfDate ?? asOfDate,
          assetId,
          assetType,
          useLatestAsOfDate: preferences?.useLatestAsOfDate ?? true
        })
      }
    } else {
      setToken(undefined)
      setPreferences(undefined) // Erases asOfDate off of client
    }
  }

  useEffect(() => {
    if (profileData) {
      const user = profileData?.me //grabs it here
      setUser(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData])

  useEffect(() => {
    if (token) {
      //Check if token has expired to save loadProfile trip
      const decoded = jwt_decode<Token>(token)

      if (decoded.exp < new Date().getTime() / 1000) {
        setToken(undefined)
        setPreferences(undefined) // erases as of date

        return
      }
      loadProfile()
    } else {
      setUser(null)
      client.resetStore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, loadProfile, client])

  const userPreferences = user?.preferences

  useEffect(() => {
    userPreferences?.language && i18n.changeLanguage(userPreferences.language)
  }, [userPreferences, i18n])

  const login = (email: string, password: string) =>
    authenticationService.login(email, password).then(res => {
      if (res.data.status === "initiate_okta")
        window.location.replace(
          `${process.env.REACT_APP_API_URL}/login_saml_init`
        )
      else setToken(res.data.access_token)
      return res.data
    })

  const register = (user: User) =>
    authenticationService
      .register(user)
      .then(data => data.data)
      .then(token => setToken(token.access_token))

  const logout = useCallback(
    async (unauthorized: boolean = false) => {
      if (unauthorized) {
        setToken(undefined)
        setPreferences(undefined)
      } else {
        await authenticationService.logout().then(res => {
          // eslint-disable-next-line
          console.log(res.data)
          setToken(undefined)
          setPreferences(undefined)
        })
      }
    },
    [setToken, setPreferences]
  )

  const login_with_token = (token: string) => {
    // the call to setToken needs to be wrapped. Otherwise we get:
    //  "Cannot update a component (`Auth`) while rendering a different component (`Login`)"
    // and useEffect can't be called here because we are inside a function
    // the suggested workout is to call setTimeout instead
    // https://stackoverflow.com/questions/62336340/cannot-update-a-component-while-rendering-a-different-component-warning
    setTimeout(() => setToken(token), 0)
    // setToken( token );
    return null
  }

  const forgot = (email: string) => authenticationService.forgot(email)

  const reset = (token: string, email: string, password: string) =>
    authenticationService
      .reset(token, email, password)
      .then(data => data.data)
      .then(token => setToken(token.access_token))

  const verify = (id: string, params: string) => {
    return authenticationService.verify(id, {
      params,
      headers: { authorization: `Bearer ${token}` }
    })
  }

  interface UpdatePasswordData {}

  const updatePassword = (data: UpdatePasswordData, params: object) =>
    authenticationService.password(data, {
      params,
      headers: { authorization: `Bearer ${token}` }
    })

  const language_detected = i18n.language?.split("-")[0]
  // i18n has a "language detector" component on the object.
  // However this has been giving strange values such as "settings". Therefore the hardcoded validator below.
  const language_detected_valid = ["en", "jp"].includes(language_detected)
  const default_lang = language_detected_valid ? language_detected : "en"
  const language = userPreferences?.language || default_lang

  const setLanguage = (language: string) => {
    if (token) {
      const values = { ...userPreferences, language }
      updatePreferences({ variables: { preferences: values } })
        .then(data => data.data.updateProfile)
        .then(user => setUser(user))
    } else {
      i18n.changeLanguage(language)
    }
  }

  const setFormPreferences = (preferences: Preferences) => {
    const values = {
      ...userPreferences,
      useLatestAsOfDate: preferences.useLatestAsOfDate
    }
    setPreferences(preferences)
    updatePreferences({ variables: { preferences: values } })
      .then(data => data.data.updateProfile)
      .then(user => setUser(user))
  }

  const shell = user?.organisation?.shell

  const { data: statusData } = useQuery(GET_AUTH_STATUS)

  useEffect(() => {
    if (statusData?.authStatus === "loggedOut") {
      setToken(undefined)
      setPreferences(undefined)
    }
    if (statusData?.unauthorized) setUnauthorized(true)
  }, [statusData, setToken, setPreferences])

  const setAsOfDate = (asOfDate: string) => {
    const values = { ...userPreferences, language, asOfDate }
    updatePreferences({ variables: { preferences: values } })
      .then(data => data.data.updateProfile)
      .then(user => setUser(user))
    setPreferences({
      asOfDate,
      assetId: preferences?.assetId ?? "",
      assetType: preferences?.assetType ?? null,
      useLatestAsOfDate: false
    })
  }

  const idRef = useRef<number>(NaN)

  useEffect(() => {
    const autoLogout = async (expiration: number) =>
      await window.setTimeout(async () => {
        if (token) {
          await logout()
            .then(() => {
              clearTimeout(idRef.current)
              idRef.current = NaN
            })
            .catch(async error => {
              // eslint-disable-next-line
              console.warn(`Auto Logout failed: ${error}`)
              await logout(true)
            })
        }
      }, expiration)

    const start_timer = async () => {
      if (token && isNaN(idRef.current)) {
        const decoded = jwt_decode<Token>(token)
        const expiration = decoded.exp * 1000 - Date.now()
        idRef.current = await autoLogout(expiration)
      }
    }

    start_timer()

    return () => {
      clearTimeout(idRef.current)
    }
  }, [token, logout])

  if (token && !user)
    return (
      <Loading
        message={process.env.NODE_ENV === "development" && "auth wait"}
      />
    )

  if (unauthorized) return <Unauthorized />

  const value = {
    token,
    user,
    setUser,
    login,
    login_with_token,
    register,
    logout,
    forgot,
    reset,
    verify,
    preferences,
    language,
    setLanguage,
    shell,
    updatePassword,
    setAsOfDate,
    setFormPreferences
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default Auth
