/* eslint-disable import/prefer-default-export */
import { Dispatch, SetStateAction, useEffect, useState } from "react"

const LOCAL_STORAGE_VERSION = "1"

const revertToMinLocalStorage = () => {
  for (var key in localStorage) {
    if (key !== "token" && key !== "preferences" && key !== "i18nextLng") {
      localStorage.removeItem(key)
    }
  }
}

const checkVersion = () => {
  if (
    parseInt(localStorage.getItem("ls_version") ?? "") >=
    parseInt(LOCAL_STORAGE_VERSION)
  ) {
    return
  }
  revertToMinLocalStorage()
  localStorage.setItem("ls_version", LOCAL_STORAGE_VERSION)
}

export function usePersistedState<S>(
  key: string,
  object: boolean = false,
  defaultValue?: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] {
  checkVersion()
  const [state, setState] = useState(() => {
    const val = localStorage.getItem(key)

    if (object) return (val && JSON.parse(val)) || defaultValue

    return val || defaultValue
  })

  useEffect(() => {
    const val = object ? JSON.stringify(state) : state

    if (val) {
      localStorage.setItem(key, val)
    } else {
      localStorage.removeItem(key)
    }
  }, [key, state, object])

  return [state, setState]
}
