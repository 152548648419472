import { Menu } from "@material-ui/core"
import React from "react"

export default function DropdownMenu(props) {
  const { children, ...other } = props

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      {...other}
    >
      {children}
    </Menu>
  )
}
