import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  logo: {
    width: "90%",
    [theme.breakpoints.up("md")]: {
      height: "28px",
      width: "auto"
    }
  },
  footerSection: {
    padding: "1em",
    paddingTop: theme.spacing(5),
    backgroundColor: "rgb(38,38,38)",
    color: "silver",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(20),
      paddingBottom: theme.spacing(10)
    }
  },
  footerLogo: {
    marginBottom: theme.spacing(2)
  },
  footerMenu: {
    color: "silver",
    textAlign: "right",
    "& a": {
      color: "silver",
      textDecoration: "none",
      paddingLeft: theme.spacing(2)
    },
    "& a:hover": {
      color: "lightgray",
      textDecoration: "none"
    }
  },
  footerMessage: {
    paddingTop: theme.spacing(3),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  footerLocation: {
    marginTop: theme.spacing(6)
  },
  footerLocationAddress: {
    color: "grey"
  },
  copyRight: {
    paddingTop: theme.spacing(2),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  dividerSection: {
    textAlign: "center",
    margin: "1em"
  },
  divider: {
    background: "white",
    width: "2px",
    marginLeft: theme.spacing(3)
  }
}))

type FooterProps = {
  imgPath: string
}

export default function Footer(props: FooterProps) {
  const { imgPath } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.footerSection} justifyContent="center">
      <Grid
        container
        item
        xs={12}
        md={2}
        justifyContent="flex-end"
        alignContent="flex-start"
      >
        <Grid item xs={11}>
          <Button color="secondary" component={Link} to="/contact">
            {t("Contact Us")}
          </Button>
        </Grid>
        <Grid item xs={11}>
          <Button color="inherit" component={Link} to="/terms">
            {t("Terms & Conditions")}
          </Button>
        </Grid>
        <Grid item xs={11}>
          <Button color="inherit" component={Link} to="/privacy">
            {t("privacyPolicy")}
          </Button>
        </Grid>
        <Grid item xs={11} className={classes.footerLocation}>
          <Typography variant="subtitle2" className={classes.footerLocation}>
            {t("TOKYO")}
          </Typography>
          <Typography
            variant="overline"
            display="block"
            className={classes.footerLocationAddress}
          >
            {t("Aoyama Tower Place 8F")}
          </Typography>
          <Typography
            variant="overline"
            display="block"
            className={classes.footerLocationAddress}
          >
            {t("Tokyo, 107-0052, Japan")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={1} md={1} className={classes.dividerSection}>
        <Divider orientation="vertical" className={classes.divider} />
      </Grid>
      <Grid item container xs={12} md={3}>
        <Grid item xs={12}>
          <img
            src={imgPath + "logo-reverse@2x.png"}
            className={classes.logo}
            alt=""
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.footerMessage}>
            {t("home.team")}
          </Typography>
          <Typography variant="body2" className={classes.copyRight}>
            Copyright © 2020 Visual Alpha Co., Ltd. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
