import ErrorPage from "@/pages/error/Error"
import React from "react"

interface Error {
  message: string
}

type GraphErrorProps = {
  error: Error
}

export default function GraphError(props: GraphErrorProps) {
  const { error } = props

  return <ErrorPage errorMessage={error.message} />
}
