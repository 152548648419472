import useAuth from "@/components/auth/useAuth"
import { VA_PUBLIC_SITE } from "@/helpers/const"
import AppBar from "@material-ui/core/AppBar"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import React from "react"

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  footer: {
    textAlign: "right",
    display: "block"
  },
  logo: {
    height: "3em",
    verticalAlign: "middle"
  }
})

export default function AppFooter() {
  const classes = useStyles()
  const { shell } = useAuth()

  if (!shell) return null

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar className={classes.footer}>
          <Typography color="inherit" variant="body2">
            Powered by
            <a href={VA_PUBLIC_SITE} target="_blank" rel="noopener noreferrer">
              <img
                src={process.env.PUBLIC_URL + "/va_logo.png"}
                className={classes.logo}
                alt="logo"
              />
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}
