import axios from "axios"
import { url } from "./config"

axios.defaults.baseURL = url

const login = (email, password) => axios.post("login", { email, password })
const logout = () => axios.post("logout")
const register = user => axios.post("register", user)

const profile = params => axios.get("me", params)

const forgot = email => axios.post("forgot", { email })

const reset = (token, email, password) =>
  axios.post("reset", { token, email, password })

const resend = params => axios.get("resend", params)

const verify = (id, config) => axios.get(`verify/${id}`, config)

const password = (data, config) => axios.put("password", data, config)

const authenticationService = {
  login,
  logout,
  register,
  profile,
  forgot,
  reset,
  resend,
  verify,
  password
}

export default authenticationService
