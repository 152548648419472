import TermsText from "@/components/policies/TermsText"
import { Card, CardContent, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(5),
    textAlign: "justify"
  }
}))

export default function Terms() {
  const classes = useStyles()

  return (
    <Container maxWidth="md">
      <Card className={classes.card}>
        <CardContent>
          <TermsText />
        </CardContent>
      </Card>
    </Container>
  )
}
