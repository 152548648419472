import { Grid, Typography } from "@material-ui/core"
import React from "react"
import homePageStyles from "./homePageStyles"

type BusinessSectionProps = {
  title: string
  message: string
  image: string
}

export default function BusinessSection(props: BusinessSectionProps) {
  const { title, message, image } = props
  const classes = homePageStyles()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.businessSection}
    >
      <Grid
        item
        container
        alignItems="center"
        alignContent="center"
        xs={12}
        md={3}
        xl={2}
      >
        <img src={image} className={classes.businessSectionImage} alt="" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={4}
        xl={3}
        justifyContent="flex-start"
        className={classes.businessSectionText}
      >
        <Typography variant="h4" className={classes.businessSectionHeader}>
          {title}
        </Typography>
        <Typography variant="h6" className={classes.businessSectionText}>
          {message}
        </Typography>
      </Grid>
    </Grid>
  )
}
