import profileFragment from "@/graphql/fragments/profileFragment"
import useSnackbar from "@/helpers/add-snackbar"
import { Role } from "@/types/global"
import { gql, useMutation } from "@apollo/client"
import {
  Button,
  Chip,
  Grid,
  Toolbar,
  Typography,
  makeStyles
} from "@material-ui/core"
import { uniqueId } from "lodash"
import React from "react"
import { useHistory } from "react-router-dom"
import { StopImpersonation } from "./__generated__/StopImpersonation"
import ChangeOrg from "./ChangeOrg"

const STOP_IMPERSONATION = gql`
  mutation StopImpersonation {
    stopImpersonation {
      id
      ...profileFragment
    }
  }
  ${profileFragment}
`

const useStyles = makeStyles(theme => ({
  toolbar: {
    backgroundColor: theme.palette.secondary.main
  }
}))

interface Organisation {
  name: string
  id: string
  modules: string[]
}

interface User {
  organisation?: Organisation | null
  role: Role
  superuser: boolean
}

type SuperUserHeaderProps = {
  user: User
}

export default function SuperUserHeader(props: SuperUserHeaderProps) {
  const { user } = props
  const history = useHistory()
  const { addSnackbar } = useSnackbar()
  const classes = useStyles()

  const [stopImpersonation] = useMutation<StopImpersonation>(STOP_IMPERSONATION)

  const handleStopImpersonation = () => {
    stopImpersonation({
      // update(cache) {
      //   cache.evict({ id: "Asset:" + assetId })
      //   cache.gc()
      // }
    })
      .then(() => addSnackbar("Stopped Impersonation"))
      .then(() => history.push("/admin/organisations"))
  }

  return (
    <>
      {user?.superuser && (
        <>
          <Toolbar className={classes.toolbar}>
            <Grid
              container
              spacing={1}
              justifyContent={"flex-start"}
              alignItems="center"
            >
              <Grid item xs={2}>
                <Typography variant="h5">Super User</Typography>
              </Grid>
              {user.organisation && (
                <>
                  <Grid item container xs={3}>
                    <Grid item container xs={9}>
                      <Typography>
                        Current Organisation : {user.organisation.name}
                      </Typography>
                    </Grid>
                    <Grid item container xs={3}>
                      <ChangeOrg />
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>Org ID: {user.organisation.id}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {user?.organisation?.modules?.map(e => (
                      <Chip
                        key={uniqueId("modules_")}
                        label={e}
                        size="small"
                        color="default"
                      />
                    ))}
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>Current Role : {user.role}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      style={{ backgroundColor: "bisque" }}
                      variant="contained"
                      onClick={handleStopImpersonation}
                    >
                      <Typography>Stop Impersonation</Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Toolbar>
        </>
      )}
    </>
  )
}
