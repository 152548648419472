import { ListItemText } from "@material-ui/core"
import React from "react"

const Roles = [
  {
    value: "admin",
    label: (
      <ListItemText
        primary="Admin"
        secondary="Full access including user management"
      />
    )
  },
  {
    value: "user",
    label: (
      <ListItemText
        primary="User"
        secondary="Read and write access but no user management"
      />
    )
  },
  {
    value: "readonly",
    label: (
      <ListItemText
        primary="Readonly"
        secondary="User can view data but not edit"
      />
    )
  }
]

export default Roles
