import { VA_PUBLIC_SITE } from "@/helpers/const"

function logoutRedirect(props) {
  const { history } = props
  const isProd = !process.env.REACT_APP_ENVIRONMENT

  if (isProd) {
    window.location.href = VA_PUBLIC_SITE
    return
  }

  history.push("/login")
}

export default logoutRedirect
