import SuperUserHeader from "@/components/admin/SuperUserHeader"
import AdminMenu from "@/components/app/AdminMenu"
import LanguageChoice from "@/components/app/LanguageChoice"
import ModuleSection from "@/components/auth/ModuleSection"
import useAuth from "@/components/auth/useAuth"
import usePreferences from "@/components/auth/usePreferences"
import DetermineBaseRoute from "@/components/common/shared/DetermineBaseRoute"
import profileFragment from "@/graphql/fragments/profileFragment"
import useSnackbar from "@/helpers/add-snackbar"
import { gql, useMutation, useQuery } from "@apollo/client"
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import AccountTreeIcon from "@material-ui/icons/AccountTree"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import ExtensionIcon from "@material-ui/icons/Extension"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import ListIcon from "@material-ui/icons/List"
import MoreIcon from "@material-ui/icons/MoreVert"
import PieChartIcon from "@material-ui/icons/PieChart"
import ShuffleOnIcon from "@material-ui/icons/Shuffle"
import TableChartIcon from "@material-ui/icons/TableChart"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, Redirect, useHistory, useLocation } from "react-router-dom"
import { StopImpersonation } from "./__generated__/StopImpersonation"
import { UserOriginalOrganisation } from "./__generated__/UserOriginalOrganisation"
import EnvironmentChip from "./environment/EnvironmentChip"
import MarketsMenu from "./MarketsMenu"
import MobileMenu from "./MobileMenu"
import NotificationLink from "./NotificationLink"
import ProfileMenu from "./ProfileMenu"

export const GET_USER_ORIGINAL_ORGANISATION = gql`
  query UserOriginalOrganisation {
    me {
      id
      organisation {
        id
        name
      }
      originalOrganisation {
        id
        name
      }
    }
  }
`

const STOP_IMPERSONATION = gql`
  mutation StopConsultantImpersonation {
    stopConsultantImpersonation {
      id
      ...profileFragment
    }
  }
  ${profileFragment}
`

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "@media print": {
      display: "none"
    }
  },
  icon: {
    color: "white"
  },
  menuItem: {
    fontSize: 12,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  menuName: {
    paddingLeft: theme.spacing(0.5)
  },
  nested: {},
  grow: {
    flexGrow: 1
  },
  topMenu: {},
  smtbTopMenu: {
    background: "seagreen"
  },
  rakutenTopMenu: {
    background: "#BF0000",
    color: "#ffffff"
  },
  amOneTopMenu: {
    background:
      "linear-gradient(0deg, rgba(0,11,82,1) 0%, rgba(4,40,126,1) 84%)",
    filter:
      "progid:DXImageTransform.Microsoft.gradient(startColorstr='#000b52',endColorstr='#04287e',GradientType=1)"
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  sectionDesktop: {
    fontSize: 12,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  menuTooltip: {
    fontSize: 20
  }
}))

type ShellConfig = { title: string; menuClass: string; isPlain?: boolean }

export default function AppHeader() {
  const LOCAL_STORAGE_VERSION = "1"

  const revertToMinLocalStorage = () => {
    for (var key in localStorage) {
      if (key !== "token" && key !== "preferences" && key !== "i18nextLng") {
        localStorage.removeItem(key)
      }
    }
  }

  const checkVersion = () => {
    if (
      parseInt(localStorage.getItem("ls_version") ?? "") >=
      parseInt(LOCAL_STORAGE_VERSION)
    ) {
      return
    }
    revertToMinLocalStorage()
    localStorage.setItem("ls_version", LOCAL_STORAGE_VERSION)
  }

  checkVersion()

  const { t } = useTranslation()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<
    HTMLButtonElement | undefined
  >()
  const { user, shell } = useAuth()
  let location = useLocation()

  const orgType = user?.organisation?.type
  const baseRoute = DetermineBaseRoute(orgType || "manager")

  const isOwnerOrg = ["owner", "corporate pension", "consultant"].includes(
    orgType || ""
  )
  const isManagerOrg = ["owner", "corporate pension", "manager"].includes(
    orgType || ""
  )

  // Note should be below useAuth hook call otherwise we can't get preferences
  const { assetType } = usePreferences()

  const isManager = orgType === "manager"
  const isPortfolio = assetType === "portfolio"

  const ownerOrgAndPortfolio = isOwnerOrg && isPortfolio

  const checkModule = user?.organisation?.modules || []
  const hasAddOns = checkModule.includes("addOns")

  const { data, refetch: refetchUserOriginalOrganisation } =
    useQuery<UserOriginalOrganisation>(GET_USER_ORIGINAL_ORGANISATION)

  let isImpersonating = !!data?.me?.originalOrganisation?.id

  let impersonatedOrganisation = data?.me?.organisation?.name || ""

  const shellConfig = (shell?: string | null): ShellConfig => {
    switch (shell) {
      case "Visual Alpha":
      default:
        return { title: t("Visual Alpha"), menuClass: classes.topMenu }

      case "smtb":
        return {
          title: t("Sumitomo Mitsui Trust Bank"),
          menuClass: classes.smtbTopMenu
        }

      case "amOne":
        return {
          title: t("Rakuten 楽天投信投資顧問"),
          menuClass: classes.rakutenTopMenu,
          isPlain: true
        }
    }
  }

  const handleMobileMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(undefined)
  }

  const classes = useStyles()
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const history = useHistory()
  const { addSnackbar } = useSnackbar()

  const [stopImpersonation] = useMutation<StopImpersonation>(STOP_IMPERSONATION)

  const handleStopImpersonation = () => {
    stopImpersonation({
      update(cache) {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "UserOriginalOrganisation",
          broadcast: false
        })
      }
    })
      .then(() => refetchUserOriginalOrganisation())
      .then(() => addSnackbar("Stopped Impersonation"))
      .then(() => history.push("/consultant"))
  }

  if (!user)
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />

  const AssetList = () => (
    <ModuleSection module="Asset List">
      <Button
        className={classes.menuItem}
        color="inherit"
        component={Link}
        to={`/${baseRoute}/assetList`}
      >
        <ListIcon />
        <span className={classes.menuName}>{t("Asset List")}</span>
      </Button>
    </ModuleSection>
  )

  const Mandates = () =>
    isOwnerOrg ? (
      <Button
        className={classes.menuItem}
        color="inherit"
        component={Link}
        to={`/${baseRoute}/mandates`}
      >
        <AccountBalanceWalletIcon />
        <span className={classes.menuName}>{t("Mandates")}</span>
      </Button>
    ) : null

  const Clients = () =>
    isManager ? (
      <Button
        className={classes.menuItem}
        color="inherit"
        component={Link}
        to={`/${baseRoute}/clients`}
      >
        <AccountBalanceWalletIcon />
        <span className={classes.menuName}>{t("Clients")}</span>
      </Button>
    ) : null

  const AssetOverview = () =>
    ownerOrgAndPortfolio ? (
      <Button
        className={classes.menuItem}
        color="inherit"
        component={Link}
        to={`/${baseRoute}/overview`}
      >
        <PieChartIcon />
        <span className={classes.menuName}>{t("Asset Overview")}</span>
      </Button>
    ) : null

  const Accounts = () =>
    ownerOrgAndPortfolio ? (
      <Button
        className={classes.menuItem}
        color="inherit"
        component={Link}
        to={`/${baseRoute}/accounts`}
      >
        <AccountTreeIcon />
        <span className={classes.menuName}>{t("Accounts")}</span>
      </Button>
    ) : null

  const AssetClasses = () =>
    ownerOrgAndPortfolio ? (
      <Button
        className={classes.menuItem}
        color="inherit"
        component={Link}
        to={`/${baseRoute}/assetClasses`}
      >
        <TableChartIcon />
        <span className={classes.menuName}>{t("Asset Classes")}</span>
      </Button>
    ) : null

  const AddOns = () =>
    ownerOrgAndPortfolio && hasAddOns ? (
      <Button
        className={classes.menuItem}
        color="inherit"
        component={Link}
        to={`/${baseRoute}/addons`}
      >
        <ExtensionIcon />
        <span className={classes.menuName}>{t("Add-ons")}</span>
      </Button>
    ) : null

  return (
    <div className={classes.root}>
      <AppBar position="static">
        {user.superuser && <SuperUserHeader user={user} />}
        <Toolbar className={shellConfig(shell).menuClass}>
          <Button color="inherit" component={Link} to="/">
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
            >
              {shellConfig(shell).title || "Visual Alpha"}
            </Typography>
          </Button>
          <EnvironmentChip isPlain={shellConfig(shell).isPlain} />

          {isImpersonating && (
            <div>
              <Typography>{impersonatedOrganisation}</Typography>
            </div>
          )}

          {isImpersonating && (
            <Button color="inherit" onClick={handleStopImpersonation}>
              <HighlightOffIcon />
            </Button>
          )}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <AssetList />
            <Clients />
            <AssetOverview />
            <Accounts />
            <Mandates />
            <AssetClasses />

            <ModuleSection module={["fundList"]}>
              <Button
                className={classes.menuItem}
                color="inherit"
                component={Link}
                to={`/${baseRoute}/funds`}
              >
                <CardTravelIcon />
                <span className={classes.menuName}>{t("Funds")}</span>
              </Button>
            </ModuleSection>

            <ModuleSection module={["cashManagement"]}>
              <Button
                className={classes.menuItem}
                color="inherit"
                component={Link}
                to={`/${baseRoute}/instructions`}
              >
                <ShuffleOnIcon />
                <span className={classes.menuName}>{t("Instructions")}</span>
              </Button>
            </ModuleSection>

            {isManagerOrg && <MarketsMenu module={"owner" || "manager"} />}

            <AddOns />
            {user?.superuser && <AdminMenu />}
            {user ? (
              <React.Fragment>
                <Tooltip title={t("Notification") as string} placement="top">
                  <React.Fragment>
                    <NotificationLink isPlain={shellConfig(shell).isPlain} />
                  </React.Fragment>
                </Tooltip>
                <React.Fragment>
                  <ProfileMenu />
                </React.Fragment>
              </React.Fragment>
            ) : (
              <Button color="inherit" component={Link} to="/login">
                Login
              </Button>
            )}
            <Tooltip title={t("Language") as string} placement="top">
              <React.Fragment>
                <LanguageChoice icon />
              </React.Fragment>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <MobileMenu
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        isMobileMenuOpen={isMobileMenuOpen}
        handleMobileMenuClose={handleMobileMenuClose}
      />
    </div>
  )
}
