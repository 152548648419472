import { Chip, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import EnvironmentModal from "./EnvironmentModal"

const useStyles = makeStyles(theme => ({
  chip: {
    borderRadius: "3px",
    marginLeft: theme.spacing(1)
  }
}))

export default function EnvironmentChip({ isPlain }: { isPlain?: boolean }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const environment = process.env.REACT_APP_ENVIRONMENT

  if (!environment) return null

  const handleClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Chip
        label={<Typography variant="button">{environment}</Typography>}
        color={isPlain ? "default" : "secondary"}
        onClick={handleClick}
        className={classes.chip}
      />
      <EnvironmentModal open={open} onClose={handleClose} />
    </>
  )
}
