import AuthRedirect from "@/components/auth/Redirect"
import useAuth from "@/components/auth/useAuth"
import TermsText from "@/components/policies/TermsText"
import { Submit } from "@/components/util/form"
import profileFragment from "@/graphql/fragments/profileFragment"
import logoutRedirect from "@/helpers/logout"
import { gql, useMutation } from "@apollo/client"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  makeStyles,
  Typography
} from "@material-ui/core"
import { Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { RegisterUser } from "./__generated__/RegisterUser"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(16)
  },
  heading: {
    fontSize: "18px"
  },
  actions: {
    float: "right",
    "& button": {
      margin: theme.spacing(1)
    }
  },
  cardContent: {
    maxHeight: "500px",
    overflowY: "scroll"
  }
}))

const REGISTER_USER = gql`
  mutation RegisterUser {
    registerUser {
      id
      ...profileFragment
    }
  }
  ${profileFragment}
`

export default function Register() {
  const classes = useStyles()
  const history = useHistory()
  const { user, logout } = useAuth()
  const { t } = useTranslation()

  const [registerUser] = useMutation<RegisterUser>(REGISTER_USER)

  const handleSubmit = () => registerUser()

  const handleCancel = async () => {
    await logout()
    logoutRedirect({ history: history })
  }

  if (user?.agreed) return <AuthRedirect />

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      <Form>
        <Container maxWidth="md">
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.heading}>
                {t("Please agree to the Visual Alpha Terms and Conditions")}
              </Typography>
            </CardContent>
            <CardContent className={classes.cardContent}>
              <TermsText />
            </CardContent>
            <CardActions className={classes.actions}>
              <Button variant="outlined" onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <Submit label={t("Agree")} />
            </CardActions>
          </Card>
        </Container>
      </Form>
    </Formik>
  )
}
