export default function getIn(
  obj,
  key: string,
  def: number | string | [] | null | undefined = undefined,
  p: number = 0
) {
  const res = _getIn(obj, key, def, p)

  // If not res and key contains ?=, pass the key to removeOptionalConditionFromKey
  // and try again
  if (!res && key.includes("?=")) {
    return getIn(obj, removeOptionalConditionFromKey(key), def, p)
  }

  return res ?? def
}

const _getIn = (
  obj,
  key: string,
  def: number | string | [] | null | undefined = undefined,
  p: number = 0
) => {
  const path = key ? key.split(".") : []

  const getValue = val => {
    switch (val) {
      case "true":
        return true

      case "false":
        return false

      case "null":
        return null

      default:
        return Number(val) || val
    }
  }

  const find = (row, input) => {
    if (!row) return false
    const parts = input.split("&")

    let i = 0

    while (i < parts.length) {
      const eq = parts[i++].split(/[=(?=)]+/)

      const test = getValue(eq[1])

      if (row[eq[0]] !== test) return false
    }

    return true
  }

  while (obj && p < path.length) {
    const res = path[p++].split(/\[|\]/)

    obj = obj[res[0]]

    if (obj && res.length > 1) {
      obj = obj.find(row => find(row, res[1]))
    }
  }
  return obj ?? def
  // Changed for when null is returned from object lookup
}

// Remove only one expression using ?= (such as cond?=value)
// from the key string. A condition is a string enclosed in square of
// brackets [].
// For example, "[cond1=name&cond2?=value&cond3?=date] will become
// "[cond1=name&cond3?=date]". If applied again, will become
// "[cond1=name]".
const removeOptionalCondition = (condition: string): string => {
  const parts = condition.split("&")
  // Iterate over parts and remove the first part that includes "?="
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].includes("?=")) {
      parts.splice(i, 1)
      break
    }
  }

  let res = parts.join("&")

  if (res.charAt(res.length - 1) !== "]") {
    res = `${res}]`
  }

  return res
}

export const removeOptionalConditionFromKey = (key: string): string => {
  // If key does not contain ?=, return unchanged
  if (!key.includes("?=")) return key

  const path = key ? key.split(".") : []
  let parts: string[] = []
  // Iterate over path, if contains [ and ] in the end, it is a condition.
  // Apply removeOptionalCondition to the condition and add to parts.
  for (let i = 0; i < path.length; i++) {
    if (path[i].includes("[") && path[i].charAt(path[i].length - 1) === "]") {
      parts.push(removeOptionalCondition(path[i]))
    } else {
      parts.push(path[i])
    }
  }

  return parts.join(".")
}

export const checkAllValueExists = (
  obj: Array<any> | undefined | null,
  key: string
): boolean => {
  let allValuesExist = true
  if (!obj) return false

  obj.forEach(item => {
    if (getIn(item, key, null) === null) allValuesExist = false
  })
  return allValuesExist
}

export const checkEmpty = (
  obj: Array<any> | undefined | null,
  key: string
): boolean => {
  let empty = true
  if (!obj) return true

  obj.forEach(item => {
    if (getIn(item, key, null) === 0) empty = false
    if (getIn(item, key, null)) empty = false
  })
  return empty
}
