import AuthRedirect from "@/components/auth/Redirect"
import useAuth from "@/components/auth/useAuth"
import BottomAction from "@/components/public/home/BottomAction"
import BusinessSection from "@/components/public/home/BusinessSection"
import Footer from "@/components/public/home/Footer"
import PrimarySection from "@/components/public/home/PrimarySection"
import React from "react"
import { useTranslation } from "react-i18next"
import { Redirect, useParams } from "react-router-dom"
import Awards from "../../components/public/home/Awards"
import Testimonials from "../../components/public/home/Testimonials"

const imgPath = process.env.PUBLIC_URL + "/landing/"

export default function Home() {
  const { token } = useAuth()
  const { t, i18n } = useTranslation()
  let params = useParams<{ language: string }>()

  if (token) return <AuthRedirect />

  const language = i18n.language.split("-")[0]
  let lng = params.language

  if (language !== lng) return <Redirect to={"/" + language} />

  return (
    <React.Fragment>
      <PrimarySection />
      <BusinessSection
        image={imgPath + "illustration-business-man-climbing@2x.png"}
        title={t("home.businessSection1.title")}
        message={t("home.businessSection1.message")}
      />
      <BusinessSection
        image={imgPath + "illustration-reporting-dashboard@2x.png"}
        title={t("home.businessSection2.title")}
        message={t("home.businessSection2.message")}
      />
      <BusinessSection
        image={imgPath + "illustration-networking-blocks@2x.png"}
        title={t("home.businessSection3.title")}
        message={t("home.businessSection3.message")}
      />
      <Awards imgPath={imgPath} />
      <Testimonials />
      <BottomAction />
      <Footer imgPath={imgPath} />
    </React.Fragment>
  )
}
