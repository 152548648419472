import DropdownMenu from "@/components/util/menu/DropdownMenu"
import {
  Button,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem
} from "@material-ui/core"
import {
  AccountTree,
  Pageview,
  SettingsApplicationsRounded
} from "@material-ui/icons"
import BookIcon from "@material-ui/icons/Book"
import BookmarksIcon from "@material-ui/icons/Bookmarks"
import FindInPageIcon from "@material-ui/icons/FindInPage"
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import React from "react"
import { Link } from "react-router-dom"
import useAuth from "../auth/useAuth"

const useStyles = makeStyles(theme => ({
  menuName: {
    fontSize: 16,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}))

export default function AdminMenu() {
  const classes = useStyles()
  const { user } = useAuth()

  const current_org = user?.organisation?.id

  return (
    <PopupState variant="popover" popupId="reports">
      {popupState => (
        <React.Fragment>
          <Button color="inherit" {...bindTrigger(popupState)}>
            <span className={classes.menuName}>Admin</span>
          </Button>
          <DropdownMenu {...bindMenu(popupState)}>
            <MenuItem
              component={Link}
              to="/admin/assets"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Assets" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/datasetcomparisontool"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <Pageview />
              </ListItemIcon>
              <ListItemText primary="Dataset Comparison Tool" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/banks"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Banks" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/branches"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Branches" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/bankAccounts"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Bank Accounts" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/countries"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Countries" />
            </MenuItem>

            <MenuItem
              component={Link}
              to="/admin/categoryTrees"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <AccountTree />
              </ListItemIcon>
              <ListItemText primary="Category Trees" />
            </MenuItem>

            <MenuItem
              component={Link}
              to="/admin/entities"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Entities" />
            </MenuItem>

            <MenuItem
              component={Link}
              to="/admin/entitypackages"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Entity Packages" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/funds"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Funds" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={"/admin/organisations/" + current_org}
              onClick={popupState.close}
            >
              <ListItemIcon>
                <SettingsApplicationsRounded />
              </ListItemIcon>
              <ListItemText primary="Current Organisation Settings" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/organisations"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Organisations" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/organisationPackages"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Organisation Packages" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/users"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/uploads"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Uploads" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/admin/instructionLivedocsLookup"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <FindInPageIcon />
              </ListItemIcon>
              <ListItemText primary="Livedocs Lookup" />
            </MenuItem>
          </DropdownMenu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
