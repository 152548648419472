import { User } from "@/components/auth/AuthContext"
import useAuth from "@/components/auth/useAuth"
import { useEffect } from "react"
import ReactGA from "react-ga4"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

type Params = {
  page: String
  location: String
  hostname: String
  language: String
  userId?: String
}

interface Location {
  pathname: string
}

const sendPageView = (
  location: Location,
  user: User | null,
  language: string
) => {
  let params: Params = {
    page: location.pathname,
    location: window.location.href,
    hostname: window.location.hostname,
    language
  }

  if (user) params.userId = user.email

  ReactGA.set(params)
  ReactGA.send(location.pathname)
}

const GAListener: any = ({ trackingId, children }) => {
  let history = useHistory()
  let { user } = useAuth()
  let { i18n } = useTranslation()
  let language = i18n.language

  useEffect(() => {
    const testMode = process.env.NODE_ENV === "test"

    if (trackingId) {
      // Part of CSP https://visualalpha.atlassian.net/wiki/spaces/PROJECTS/pages/1147994119/Content+Security+Policy+CSP
      // nonce values rounded to 10 seconds
      const nonce = Buffer.from(
        (Math.floor(Date.now() / 10000) * 10000).toString()
      ).toString("base64")

      ReactGA.initialize(trackingId, { testMode, nonce })
      sendPageView(history.location, user, language)
    }

    return history.listen(location => sendPageView(location, user, language))
  }, [history, trackingId, user, language])
  return children
}

export default GAListener
