import useAuth from "@/components/auth/useAuth"
import DropdownMenu from "@/components/util/menu/DropdownMenu"
import useSnackbar from "@/helpers/add-snackbar"
import { Button, makeStyles, MenuItem } from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import LanguageIcon from "@material-ui/icons/Language"
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import React from "react"

const useStyles = makeStyles(theme => ({
  menuItem: {
    fontSize: 12,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  menuName: {
    paddingLeft: theme.spacing(0.5)
  }
}))

const languages = [
  {
    value: "en",
    label: "ENGLISH"
  },
  {
    value: "jp",
    label: "日本語"
  }
]

type LanguageChoiceProps = {
  icon?: boolean
}

interface IconProps {
  onClick: (event: React.SyntheticEvent<any, Event>) => void
}

const LanguageChoice = React.forwardRef((props: LanguageChoiceProps, ref) => {
  const { icon } = props
  const { addSnackbar } = useSnackbar()
  const { language, setLanguage } = useAuth()
  const classes = useStyles()

  const handleClick = (language: string) => {
    setLanguage(language)
    addSnackbar("Language Changed")
  }

  const result = languages.find(l => l.value === language)

  const label = result ? result.label : language

  const Icon = ({ onClick }: IconProps) => (
    <Button onClick={onClick} color="inherit" className={classes.menuItem}>
      {icon ? <LanguageIcon /> : label}
      <ArrowDropDownIcon />
    </Button>
  )

  return (
    <PopupState variant="popover" popupId="reports">
      {popupState => (
        <React.Fragment>
          <Icon {...bindTrigger(popupState)} />
          <DropdownMenu {...bindMenu(popupState)}>
            {languages.map(item => (
              <MenuItem
                onClick={() => {
                  handleClick(item.value)
                  popupState.close()
                }}
                key={item.value}
                selected={item.value === language}
              >
                {item.label}
              </MenuItem>
            ))}
          </DropdownMenu>
        </React.Fragment>
      )}
    </PopupState>
  )
})

export default LanguageChoice
