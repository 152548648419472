import AuthContext from "@/components/auth/AuthContext"
import Error from "@/pages/error/Error"
import NotFound from "@/pages/error/NotFound"
import Unauthorized from "@/pages/error/Unauthorized"
import axios from "axios"
import React, { Component } from "react"
import { withRouter } from "react-router"

class AxiosHandler extends Component {
  state = {
    notfound: null,
    error: null,
    unauthorized: null
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.setState({ error: null, notfound: null })
    }
  }

  props: any
  requestInterceptor: number
  responseInterceptor: number

  constructor(props: any) {
    super(props)

    // Set axios interceptors
    this.requestInterceptor = axios.interceptors.request.use(req => {
      this.setState({ notfound: null, error: null })

      const token = this.context.token

      // Add Bearer authorization header if user is logged in
      req.headers.authorization = token ? `Bearer ${token}` : null

      return req
    })

    this.responseInterceptor = axios.interceptors.response.use(
      res => res,
      error => {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              // unauthorized
              this.context.logout(true)
              break

            case 403:
              this.setState(
                error.response.data.message ===
                  "Your email address is not verified."
                  ? { unverified: true }
                  : { unauthorized: true }
              )

              break

            case 404:
              this.setState({ notfound: true })
              break

            case 422:
              return Promise.reject(error)

            default:
              this.setState({ error })
          }
        }

        throw new axios.Cancel("Operation canceled by the user.")
      }
    )
  }

  componentWillUnmount() {
    // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed
    axios.interceptors.request.eject(this.requestInterceptor)
    axios.interceptors.response.eject(this.responseInterceptor)
  }

  render() {
    if (this.state.error) return <Error />
    if (this.state.unauthorized) return <Unauthorized />
    if (this.state.notfound) return <NotFound />

    return this.props.children
  }
}

AxiosHandler.contextType = AuthContext

export default withRouter(AxiosHandler)
