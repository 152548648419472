import AuthContext from "@/components/auth/AuthContext"
import { useContext } from "react"

const MISSING_CONTEXT_ERROR: Error = new Error(
  "useAuth may only be called within a <Auth /> context."
)

export default function useAuth() {
  const context = useContext(AuthContext)

  if (!context) {
    throw MISSING_CONTEXT_ERROR
  }

  return context
}
