const DetermineBaseRoute = (orgType: string | undefined): string => {
  switch (orgType) {
    case "consultant":
      return "consultant"
    case "manager":
      return "manager"
    case "owner":
    case "corporate pension":
    case "bank":
    case "endowment":
    case "public pension":
    case "sovereign fund":
    case "family office":
    case "insurance":
    case "corporate":
      return "owner"
    default:
      return "manager"
  }
}

export default DetermineBaseRoute
