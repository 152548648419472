import ConfirmationModal from "@/components/common/shared/forms/modals/ConfirmationModal"
import { Button, makeStyles, Typography } from "@material-ui/core"
import RefreshIcon from "@material-ui/icons/Refresh"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: "center",
    marginTop: 50,
    marginBottom: 20
  },
  message: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 20
  },
  button: {
    width: "100%",
    maxWidth: 300,
    height: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: 20
  }
}))

type Props = {
  debugMessage?: string
  errorMessage?: string
}

const Error: React.FC<Props> = ({ errorMessage, debugMessage }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [resetDialogOpen, setResetDialogOpen] = useState(false)

  return (
    <React.Fragment>
      <div className={classes.heading}>
        <Typography variant="h4">{t("error.generic.title")}</Typography>
      </div>
      {!errorMessage?.includes("Internal") && (
        <>
          <div className={classes.message}>
            <Typography variant="body2">
              {t("error.generic.reset.message")}
            </Typography>
          </div>
          <div className={classes.message}>
            <Button
              color="secondary"
              variant="contained"
              className={classes.button}
              onClick={() => setResetDialogOpen(true)}
              startIcon={<RefreshIcon />}
            >
              {t("Reset All Settings")}
            </Button>
            <ConfirmationModal
              isForm={false}
              open={resetDialogOpen}
              onClose={() => setResetDialogOpen(false)}
              message={t("Are you sure you want to reset all settings?")}
              title={t("Reset All Settings")}
              onClickSubmit={() => {
                localStorage.clear()
                setResetDialogOpen(false)
                window.location.reload()
                return
              }}
            />
          </div>
        </>
      )}
      <div className={classes.message}>
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
        <Typography variant="body1" color="error">
          {debugMessage}
        </Typography>
      </div>
      <div className={classes.message}>
        <Typography variant="body2">
          {t("error.generic.message")}
          <a href="mailto:support@visual-alpha.com">support@visual-alpha.com</a>
        </Typography>
      </div>
    </React.Fragment>
  )
}

export default Error
