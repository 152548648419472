import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  bottomActionSection: {
    textAlign: "center",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    padding: "1em"
  },
  bottomActionSectionSubTitle: {
    marginTop: theme.spacing(4)
  },
  actionButton: {
    margin: theme.spacing(3),
    textAlign: "center"
  }
}))

export default function BottomAction() {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.bottomActionSection}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h5">
          {t("home.bottomActionSection.title")}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          className={classes.actionButton}
          component={Link}
          to="/contact"
        >
          {t("Contact Us")}
        </Button>
        <Typography
          variant="h6"
          className={classes.bottomActionSectionSubTitle}
        >
          {t("home.bottomActionSection.subtitle")}
        </Typography>
        <Typography variant="body1">
          {t("home.bottomActionSection.submessage")}
        </Typography>
      </Grid>
    </Grid>
  )
}
