import {
  Dialog,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))

type EnvironmentModalType = {
  open: boolean
  onClose: () => void
}

export default function EnvironmentModal(props: EnvironmentModalType) {
  const { open, onClose } = props
  const classes = useStyles()

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        {process.env.REACT_APP_ENVIRONMENT}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <List>
        <ListSubheader>git</ListSubheader>
        <ListItem>
          <ListItemText
            primary={
              <Link
                href={
                  "https://github.com/visualalpha/app/commit/" +
                  process.env.REACT_APP_GIT_SHA
                }
                target="_blank"
              >
                {process.env.REACT_APP_GIT_SHA}
              </Link>
            }
          />
        </ListItem>
        <ListSubheader>end points</ListSubheader>
        <ListItem>
          <ListItemText primary={process.env.REACT_APP_API_URL} />
        </ListItem>
      </List>
    </Dialog>
  )
}
