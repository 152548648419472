import { gql } from "@apollo/client"

const profileFragment = gql`
  fragment profileFragment on Me {
    id
    name
    email
    phone_number
    role
    title
    notificationsUnreadCount
    organisation {
      id
      name
      type
      shell
      ref
      modules
      organisationPackages {
        data {
          id
          type
        }
      }
      assets {
        data {
          id
          type
          name
        }
      }
    }
    preferences
    asOfDate
    assetId
    assetType
    superuser
    agreed
    password_reset
  }
`
export default profileFragment
