import FormConfirmationModal from "@/components/common/shared/forms/modals/FormConfirmationModal"
import ModalActions from "@/components/common/shared/forms/modals/ModalActions"
import ModalHeader from "@/components/common/shared/forms/modals/ModalHeader"
import DebugDisplay from "@/helpers/DebugDisplay"
import MomentUtils from "@date-io/moment"
import { Dialog, DialogContent, makeStyles } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Form, Formik, FormikHelpers } from "formik"
import React, { ReactNode } from "react"
import LoadingModal from "../LoadingModal"

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "400px",
    margin: 0,
    padding: theme.spacing(2)
  }
}))

export interface FormDialogProps {
  title: string
  open: boolean
  onClose?: () => void
  onClickLabel?: string
  initialValues: any
  validationSchema?: any
  onSubmit: (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => void | Promise<any>
  confirmable?: boolean
  confirmationTitle?: string
  confirmationMessage?: string
  children?: ReactNode
  disableEscapeKeyDown?: boolean
  displayFormState?: boolean
}

const FormDialog: React.FC<FormDialogProps> = ({
  title,
  open,
  onClose,
  onClickLabel = "",
  initialValues,
  validationSchema,
  onSubmit,
  confirmable,
  confirmationTitle,
  confirmationMessage,
  displayFormState,
  children,
  ...other
}: FormDialogProps) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose && onClose()
        }
      }}
      aria-labelledby="form-dialog-title"
      scroll={"paper" as "paper"}
      classes={{ paper: classes.root }}
      {...other}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          dirty,
          isValid,
          isInitialValid,
          isValidating,
          initialErrors,
          initialTouched,
          initialStatus
        }) => (
          <Form>
            <ModalHeader onClose={onClose}>{title}</ModalHeader>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DialogContent>{children}</DialogContent>
            </MuiPickersUtilsProvider>
            <ModalActions
              confirmationTitle={confirmationTitle}
              confirmationMessage={confirmationMessage}
              confirmButtonText={onClickLabel}
              confirmable={confirmable}
              onClose={onClose}
              ConfirmationModal={FormConfirmationModal}
              disabled={isSubmitting || !isValid}
              isForm
            />
            {displayFormState ? (
              <DebugDisplay
                {...{
                  values,
                  errors,
                  dirty,
                  isValid,
                  isSubmitting,
                  isInitialValid,
                  isValidating,
                  initialErrors,
                  initialTouched,
                  initialStatus
                }}
              />
            ) : (
              <></>
            )}
            <LoadingModal open={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
export default FormDialog
