import {
  CircularProgress,
  makeStyles,
  Modal,
  Typography
} from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    outline: 0
  },
  text: {
    color: "white",
    marginBottom: "20px",
    textAlign: "center"
  },
  centerbox: {
    textAlign: "center",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  }
}))

export default function LoadingModal({
  open,
  text = ""
}: {
  open: boolean
  text?: string
}) {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
    >
      <div className={classes.paper}>
        <Typography variant="h6" className={classes.text}>
          {text}
        </Typography>
        <CircularProgress className={classes.centerbox} />
      </div>
    </Modal>
  )
}
