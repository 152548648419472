import useAuth from "@/components/auth/useAuth"
import { TextField } from "@/components/util/form/fields"
import Submit from "@/components/util/form/Submit"
import { makeStyles, Typography } from "@material-ui/core"
import { Field, Form, Formik, FormikHelpers } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import * as Yup from "yup"

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "20px"
  },
  submit: {
    marginTop: theme.spacing(4),
    padding: 8,
    fontSize: "16px"
  }
}))

export default function LoginForm() {
  const classes = useStyles()
  let location = useLocation()
  let history = useHistory()
  const { login } = useAuth()
  const { t } = useTranslation()

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required(t("Required")),
    password: Yup.string().required(t("Required"))
  })

  const initialValues = {
    email: "",
    password: ""
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={(
        { email, password },
        { setStatus, setSubmitting }: FormikHelpers<any>
      ) => {
        login(email, password)
          .then(user => {
            const { from } = (location.state as any) || {
              from: { pathname: "/" }
            }
            history.push(from)
          })
          .catch(error => {
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.password) {
                const passwordErrors = error.response.data.errors.password.map(
                  function (msg) {
                    return t(msg)
                  }
                )
                error.response.data.errors.password = passwordErrors
              } else if (error.response.data.errors.ip) {
                const { from } = (location.state as any) || {
                  from: { pathname: "/notallowedip" }
                }
                history.push(from, { ip: error.response.data.errors.ip })
                return
              }
              setStatus(error.response.data.errors)
              setSubmitting(false)
            }
            return Promise.reject(error)
          })
      }}
    >
      <Form>
        <Typography className={classes.heading}>
          {t("Sign in to your account")}
        </Typography>

        <Field
          name="email"
          label={t("Email")}
          type="email"
          component={TextField}
          autoComplete="username"
          fullWidth
        />

        <Field
          name="password"
          label={t("Password")}
          component={TextField}
          type="password"
          autoComplete="current-password"
          fullWidth
        />

        <Submit label={t("Login")} fullWidth className={classes.submit} />
      </Form>
    </Formik>
  )
}
