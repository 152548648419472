import HomeHeader from "@/components/app/HomeHeader"
import React from "react"
import { Route } from "react-router-dom"

export default function RouteHome({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <HomeHeader>
          <>
            <Component {...matchProps} />
          </>
        </HomeHeader>
      )}
    />
  )
}
