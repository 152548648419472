import { makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: "center",
    marginTop: 50
  }
}))

export default function NotFound() {
  const classes = useStyles()

  return (
    <div className={classes.heading}>
      <header>
        <Typography variant="h4">Page Not Found</Typography>
      </header>
    </div>
  )
}
