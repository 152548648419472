/* eslint-disable no-console */
import { GET_AUTH_STATUS } from "@/components/auth/Auth"
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Observable
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"

const connections = {}

const cancelRequestLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
    // Set x-CSRF token (not related to abort use case)
    const context = operation.getContext()
    /** Final touch to cleanup */

    const connectionHandle = forward(operation).subscribe({
      next: (...arg) => observer.next(...arg),
      error: (...arg) => {
        cleanUp()
        observer.error(...arg)
      },
      complete: (...arg) => {
        cleanUp()
        observer.complete(...arg)
      }
    })

    const cleanUp = () => {
      connectionHandle?.unsubscribe()
      delete connections[context.requestId]
    }

    if (context?.requestId) {
      const controller = new AbortController()
      controller.signal.onabort = cleanUp
      operation.setContext({
        ...context,
        fetchOptions: {
          signal: controller.signal,
          ...context?.fetchOptions
        }
      })
      if (connections[context.requestId]) {
        // If a controller exists, that means this operation should be aborted.
        connections[context.requestId]?.abort()
      }

      connections[context.requestId] = controller
    }

    return connectionHandle
  })
})

const cache = new InMemoryCache({
  typePolicies: {
    Dataset: {
      fields: {
        data: {
          merge: (existing, incoming) => ({ ...existing, ...incoming })
        }
      }
    }
  }
})

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL + "/graphql"
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  }
})

const moduleLink = setContext((_operation, previousContext) => {
  const { headers, module } = previousContext

  if (!module) {
    return previousContext
  }

  return {
    ...previousContext,
    headers: {
      ...headers,
      "authorization-module": module
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, debugMessage }) => {
      if (message === "Unauthenticated.") {
        cache.writeQuery({
          query: GET_AUTH_STATUS,
          data: { authStatus: "loggedOut", unauthorized: null }
        })
        response.errors = null
      } else if (debugMessage === "This action is unauthorized.") {
        cache.writeQuery({
          query: GET_AUTH_STATUS,
          data: { authStatus: null, unauthorized: true }
        })
        response.errors = null
      } else {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
    })
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const resolvers = {
  Query: {
    authStatus: () => null,
    unauthorized: () => null
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    moduleLink,
    authLink,
    cancelRequestLink,
    uploadLink
  ]),
  cache,
  resolvers
})

cache.writeQuery({
  query: GET_AUTH_STATUS,
  data: { authStatus: null, unauthorized: null }
})

export default client
