import { CircularProgress, makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    outline: 0
  }
}))

export default function Loading(props) {
  const { message } = props

  const classes = useStyles()
  return (
    <div className={classes.paper}>
      <CircularProgress />
      {message && <Typography>{message}</Typography>}
    </div>
  )
}
