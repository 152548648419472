import useAuth from "@/components/auth/useAuth"
import usePreferences from "@/components/auth/usePreferences"
import React from "react"
import { Redirect as BaseRedirect } from "react-router-dom"

export default function Redirect() {
  const { user } = useAuth()
  const { assetType } = usePreferences()

  const o = "owner"
  const c = "consultant"
  const m = "manager"

  const mod = user?.organisation?.modules
  const isOwner = mod?.includes(o)
  const isConsultant = mod?.includes(c)
  const isManager = mod?.includes(m)
  const hasAssetList = mod?.includes("Asset List")

  const redirect = (type: string | null, mod: string) =>
    type === "benchmark" ? (
      <BaseRedirect to={`/${mod}/mandates`} />
    ) : (
      <BaseRedirect to={`/${mod}/overview`} />
    )

  if (isOwner) {
    return redirect(assetType, o)
  }

  if (isManager) {
    return <BaseRedirect to="/manager/clients" />
  }

  if (isConsultant) {
    return hasAssetList ? (
      <BaseRedirect to="/consultant/assetList" />
    ) : (
      redirect(assetType, c)
    )
  }

  if (user?.superuser) {
    return <BaseRedirect to="/admin/organisations" />
  }

  return <BaseRedirect to="/research" />
}
