import PrivacyEn from "@/pages/policies/privacy/content/PrivacyEn"
import PrivacyJp from "@/pages/policies/privacy/content/PrivacyJp"
import { Card, CardContent, Container, makeStyles } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(5),
    padding: theme.spacing(5),
    textAlign: "justify",
    "& a": {
      color: "grey"
    }
  }
}))

const privacyPages = {
  jp: <PrivacyJp />,
  en: <PrivacyEn />
}

export default function Privacy() {
  const classes = useStyles()
  const { i18n } = useTranslation()
  return (
    <Container maxWidth="md">
      <Card className={classes.card}>
        <CardContent>{privacyPages[i18n.language]}</CardContent>
      </Card>
    </Container>
  )
}
