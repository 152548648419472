import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { en, jp } from "./locales"

const resources = {
  en: {
    translation: en
  },
  jp: {
    translation: jp
  },
  ja: {
    translation: jp
  }
}

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    },

    load: "all",
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    fallbackLng: false,
    returnEmptyString: false, //fallback to key

    defaultNS: "translation",

    detection: {
      order: [
        "localStorage",
        "path",
        "navigator",
        "htmlTag",
        "subdomain",
        "querystring",
        "cookie"
      ],
      lookupLocalStorage: "i18nextLng"
    }
  })
