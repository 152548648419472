import useAuth from "@/components/auth/useAuth"
import { Card, Container, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { Redirect, useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(20),
    padding: theme.spacing(3)
  }
}))

export default function WrongIP() {
  const classes = useStyles()
  const { token } = useAuth()

  const history = useHistory()

  const ip = (history.location.state as any)?.ip ?? ""

  if (token) return <Redirect to="/" />

  return (
    <Container maxWidth="xs">
      <Card className={classes.card}>
        <Typography variant="h5">
          {`Your IP address ${ip} is not allowed to log in to this organisation.`}
        </Typography>
      </Card>
    </Container>
  )
}
