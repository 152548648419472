import { makeStyles, Typography } from "@material-ui/core"
import { connect } from "formik"
import React from "react"

const useStyles = makeStyles(theme => ({
  error: {
    textAlign: "center",
    color: "red",
    marginTop: 50
  }
}))

function Errors(props) {
  const classes = useStyles()
  const errors = props.formik.errors
  if (errors && Object.values(errors).length > 0) {
    // console.log(errors)
    return (
      <ul>
        {Object.values(errors).map((error, index) => (
          <li key={index}>
            <Typography variant="body1" className={classes.error}>
              {index}
              {error}
            </Typography>
          </li>
        ))}
      </ul>
    )
  } else {
    return null
  }
}

export default connect(Errors)
