import AppFooter from "@/components/app/AppFooter"
import AppHeader from "@/components/app/AppHeader"
import AxiosHandler from "@/components/AxiosHandler"
import ErrorBoundary from "@/components/util/error/ErrorBoundary"
import Loading from "@/components/util/Loading"
import { makeStyles } from "@material-ui/core"
import React, { lazy, Suspense } from "react"
import { Route, Switch } from "react-router-dom"

const OwnerRoutes = lazy(() => import("@/routes/CombinedOwnerRoutes"))
const ManagerRoutes = lazy(() => import("@/routes/Manager"))
const ConsultantRoutes = lazy(() => import("@/routes/Consultant"))
const AdminRoutes = lazy(() => import("@/routes/Admin"))
const SettingsRoutes = lazy(() => import("@/routes/Settings"))

const publicRoutesPromise = import("@/routes/Public")
const PublicRoutes = lazy(() => publicRoutesPromise)

const useStyles = makeStyles(theme => ({
  main: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2)
    },
    minHeight: "calc(100vh - 128px)"
  }
}))

export default function App() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <AppHeader />
      <ErrorBoundary>
        <main className={classes.main}>
          <AxiosHandler>
            <Suspense
              fallback={
                <Loading
                  message={
                    process.env.NODE_ENV === "development" && "route loading"
                  }
                />
              }
            >
              <Switch>
                <Route path="/owner">
                  <OwnerRoutes />
                </Route>
                <Route path="/manager">
                  <ManagerRoutes />
                </Route>
                <Route path="/consultant">
                  <ConsultantRoutes />
                </Route>
                <Route path="/admin">
                  <AdminRoutes />
                </Route>
                <Route path="/settings">
                  <SettingsRoutes />
                </Route>

                <Route>
                  <PublicRoutes />
                </Route>
              </Switch>
            </Suspense>
          </AxiosHandler>
        </main>
      </ErrorBoundary>
      <AppFooter />
    </React.Fragment>
  )
}
