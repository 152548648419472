import useAuth from "@/components/auth/useAuth"
import { Button, Grid } from "@material-ui/core"
import { ArrowDropDown, ArrowRight } from "@material-ui/icons"
import React, { useState } from "react"

const DebugDisplay = props => {
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  return user?.superuser ? (
    <>
      <Grid container>
        <Grid item>
          <Button
            style={{ background: "brown", color: "white" }}
            onClick={() => setOpen(e => !e)}
            endIcon={open ? <ArrowDropDown /> : <ArrowRight />}
          >
            Debug {props?.title}
          </Button>
        </Grid>
        {open && (
          <>
            <Grid item>
              <Button
                onClick={() =>
                  navigator.clipboard.writeText(JSON.stringify(props, null, 2))
                }
              >
                Copy
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div style={{ margin: "1rem 0" }}>
                <h5>Form State:</h5>
                <pre
                  style={{
                    background: "#020202",
                    color: "#e8e4ac",
                    fontSize: ".75rem",
                    padding: "1rem"
                  }}
                >
                  <strong>props</strong> ={JSON.stringify(props, null, 2)}
                </pre>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  ) : (
    <></>
  )
}

export default DebugDisplay
