import { makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  header: {
    textAlign: "center",
    margin: theme.spacing(3)
  }
}))

const PrivacyJp = () => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h4" className={classes.header}>
        Visual Alpha株式会社
      </Typography>
      <Typography variant="h4" className={classes.header}>
        個人情報の取扱について （指針）
      </Typography>
      <p>
        この「個人情報の取扱について」（以下「本指針」）では、Visual
        Alpha株式会社（以下「当社」）が当社のWebサイト閲覧またはメール等他のアクセスにより取得し、利用する個人情報に関して、当社の個人情報に関する基本的な指針を定めるものです。
      </p>
      <p>
        当社は個人情報を取り扱う場合には、本指針個人情報の保護に関する法律、その他関係法令を遵守いたします。
      </p>

      <Typography variant="h6">【個人情報保護の方針】</Typography>
      <ul>
        <li>
          当社の事業内容に即し、適切に個人情報を取得・利用・管理する様定めた社内規程を遵守いたします。
        </li>
        <li>
          個人情報の漏洩、紛失等を防止する措置を講じるとともに、万一こうした事態の発生時には、速やかに再発防止措置を講じます。
        </li>
        <li>
          個人情報の取扱いに関する法令、国が定める指針等関係法令を遵守します。
        </li>
        <li>定期的に当社の管理体制をチェックし、必要に応じて見直します。</li>
      </ul>

      <Typography variant="h6">【当社が扱う個人情報】</Typography>
      <ul>
        <li>個人識別情報（氏名、e-mail、電話番号等）</li>
      </ul>

      <Typography variant="h6">【個人情報取得手段】</Typography>
      <p>
        当社は当該個人本人が以下の手段で当社に個人識別データを提供し、その利用に同意した場合に個人識別データを取得・利用します。
      </p>
      <ul>
        <li>オンラインで登録し、当社のサービスを利用する場合</li>
        <li>
          当社が実施する任意のカスタマー・サーベイまたは他のチャネルから実施される同様のサーベイに回答した場合
        </li>
        <li>当社Websiteを自らのブラウザーを使って利用もしくは閲覧した場合</li>
      </ul>

      <Typography variant="h6">【個人情報の利用目的】</Typography>
      <p>
        当社は、利用目的を特定し、あらかじめ本人の同意を得た場合に、個人情報保護に関する法律、その他関係法令を遵守し、下記の目的で個人情報を利用します。
      </p>
      <ul>
        <li>顧客からの取引発注並びに顧客アカウントの管理</li>
        <li>E-mailを使った当社からのキャンペーンや各種サービスのご案内</li>
        <li>各種セミナーのご案内</li>
        <li>顧客満足度等のアンケートの依頼</li>
        <li>お客様に対するサービスの向上、当社理解度の促進</li>
        <li>お客様との契約の締結、契約関係の管理</li>
        <li>従業員等の雇用、人事管理</li>
        <li>株主との連絡、株主関係手続</li>
      </ul>

      <Typography variant="h6">【第三者への提供について】</Typography>
      <p>
        当社は、当社が取得した個人情報を、下記のいずれかの場合を除いていかなる第三者にも提供いたしません。
      </p>
      <ul>
        <li>ご本人の同意に基づくまたは指示による場合</li>
        <li>
          お客様の人身・生命または財産の保護のために緊急に必要があり、かつご本人のご同意をいただくことが困難な場合
        </li>
        <li>
          警察等司法当局、税務当局等からの法令・公権力に基づく要請がある場合
        </li>
      </ul>

      <Typography variant="h6">
        【登録内容の開示、修正及び利用中止について】
      </Typography>
      <p>
        当社は、当社の適正な業務運営に支障をきたさない範囲で、ご本人からの要請に基づき、当該ご本人に関する個人情報を本人確認手続実施の上で開示します。
      </p>
      <p>
        当社が保管する個人情報や、ご本人に開示した個人情報に誤りがあった場合には、当該個人情報を修正します。またご本人から個人情報利用停止の申し出があった場合には、直ちにその利用を停止します。
      </p>

      <Typography variant="h6">【クッキーの使用について】</Typography>
      <p>
        当社は当社Websiteにおいて、ご利用者により良いサービスを提供するために、一部のコンテンツにおいて、クッキーまたはそれと類似の技術を利用しています。その目的は、利用者のWeb接続の維持、再度訪問時のアクセスの迅速化並びに過去に反復して閲覧したページ等当社Websiteの利用状況の把握などです。
      </p>

      <Typography variant="h6">（注）クッキー（Cookie）について</Typography>
      <p>
        当社のホームページにアクセスするお客様のコンピューターに、当社サイトへのアクセス情報等を格納する技術です。これにより次回以降アクセスする場合、前回のアクセス履歴を使い迅速にアクセスすることが可能となります。クッキーはお客様のコンピューターを識別しますが、お客様の個人情報を取得することはありません。
      </p>

      <Typography variant="h6">
        【当社Websiteとリンクを有する他のWebsiteとの関係について】
      </Typography>
      <p>
        本指針はあくまで当社Websiteに適用されます。当社Websiteには他社のWebsiteとのリンクがあります。お客様がこのリンクを利用して他者のWebsiteを閲覧される場合には他社が提供する個人情報の取扱についての表明をご参照ください。
      </p>

      <Typography variant="h6">【指針の改定について】</Typography>
      <p>
        当社は定期的に見直し、関係する法令等の改正などに即して随時改定いたします。
      </p>
    </div>
  )
}

export default PrivacyJp
