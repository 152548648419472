import AuthRedirect from "@/components/auth/Redirect"
import useAuth from "@/components/auth/useAuth"
import { Submit } from "@/components/util/form"
import { TextField } from "@/components/util/form/fields"
import profileFragment from "@/graphql/fragments/profileFragment"
import useSnackbar from "@/helpers/add-snackbar"
import logoutRedirect from "@/helpers/logout"
import { gql, useMutation } from "@apollo/client"
import {
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  Typography
} from "@material-ui/core"
import { Field, Form, Formik, FormikHelpers } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import * as Yup from "yup"
import { SetPassword, SetPasswordVariables } from "./__generated__/SetPassword"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(16)
  },
  cardContent: {
    margin: theme.spacing(3)
  },
  heading: {
    fontSize: "20px",
    paddingBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    padding: 8,
    fontSize: "16px"
  }
}))

const SET_PASSWORD = gql`
  mutation SetPassword($current: String!, $password: String!) {
    setPassword(current: $current, password: $password) {
      id
      ...profileFragment
    }
  }
  ${profileFragment}
`

export default function PasswordReset() {
  const classes = useStyles()
  let history = useHistory()
  let location = useLocation()
  const { user, logout } = useAuth()
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()

  const PasswordSchema = Yup.object().shape({
    current: Yup.string().required(t("Required")),
    password: Yup.string()
      //.min(8, t("Password must be at least eight characters long"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
        t("Password Complexity Requirements")
      )
      .max(100, t("Password must be 100 characters or fewer"))
      .required(t("Required"))
      .notOneOf(
        [Yup.ref(t("current")), null],
        t("New password must not match current password")
      ),
    password_confirmation: Yup.string()
      //.min(8, t("Password must be at least eight characters long"))
      .max(100, t("Password must be 100 characters or fewer"))
      .required(t("Required"))
      .oneOf([Yup.ref("password")], t("Passwords must match"))
  })

  const initialValues = {
    current: "",
    password: "",
    password_confirmation: ""
  }

  const [setPassword] = useMutation<SetPassword, SetPasswordVariables>(
    SET_PASSWORD
  )

  const handleSubmit = (
    values,
    { setErrors, setSubmitting }: FormikHelpers<any>
  ) =>
    setPassword({ variables: values })
      .then(() => addSnackbar(t("Password Updated")))
      .then(() => {
        const { from } = (location.state as any) || { from: { pathname: "/" } }
        history.push(from)
      })
      .catch(error => {
        setSubmitting(false)

        if (
          error.graphQLErrors &&
          error.graphQLErrors[0].extensions?.validation
        ) {
          // alert(JSON.stringify(error.graphQLErrors))
          setErrors(error.graphQLErrors[0].extensions.validation)
        }
      })

  const handleCancel = async () => {
    await logout()
    logoutRedirect({ history: history })
  }

  if (!user?.password_reset) return <AuthRedirect />

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PasswordSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Container maxWidth="xs">
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.heading}>
                {t("Please choose a new password")}
              </Typography>
              <Field
                name="current"
                label={t("Current Password")}
                type="password"
                autoComplete="current-password"
                component={TextField}
                variant="filled"
                margin="normal"
                fullWidth
              />
              <Field
                name="password"
                label={t("New Password")}
                type="password"
                autoComplete="new-password"
                component={TextField}
                variant="filled"
                margin="normal"
                fullWidth
              />
              <Field
                name="password_confirmation"
                label={t("Confirm Password")}
                type="password"
                autoComplete="new-password"
                component={TextField}
                variant="filled"
                margin="normal"
                fullWidth
              />
              <Submit
                label={t("Save")}
                color="primary"
                fullWidth
                className={classes.button}
              />
              <Button
                variant="outlined"
                fullWidth
                className={classes.button}
                onClick={handleCancel}
              >
                {t("Cancel")}
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Form>
    </Formik>
  )
}
