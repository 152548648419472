import ModuleSection from "@/components/auth/ModuleSection"
import useAuth from "@/components/auth/useAuth"
import DetermineBaseRoute from "@/components/common/shared/DetermineBaseRoute"
import DropdownMenu from "@/components/util/menu/DropdownMenu"
import { Button, ListItemIcon, makeStyles, MenuItem } from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import BusinessIcon from "@material-ui/icons/Business"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import EqualizerIcon from "@material-ui/icons/Equalizer"
import GroupIcon from "@material-ui/icons/Group"
import LocalATMIcon from "@material-ui/icons/LocalAtm"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  markets: {
    fontSize: 12,
    paddingLeft: theme.spacing(1)
  }
}))
type MarketsMenuProps = {
  module: "manager" | "consultant" | "owner" | "cash management"
}

export default function MarketsMenu(props: MarketsMenuProps) {
  const { user } = useAuth()

  const orgType = user?.organisation?.type
  const baseRoute = DetermineBaseRoute(orgType || "manager")

  const checkModule = user?.organisation?.modules || []
  const isCashManagement = checkModule.includes("cashManagement")
  // const { t, i18n } = useTranslation()
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <PopupState variant="popover" popupId="reports">
      {popupState => (
        <React.Fragment>
          <Button color="inherit" {...bindTrigger(popupState)}>
            <EqualizerIcon />
            <span className={classes.markets}>{t("Markets")}</span>
            <ArrowDropDownIcon />
          </Button>
          <DropdownMenu {...bindMenu(popupState)}>
            {/* <Divider /> */}
            <ModuleSection
              module={["manager", "owner"]}
              organisationPackage={"securities"}
            >
              <MenuItem
                component={Link}
                to={`/${baseRoute}/securities`}
                onClick={popupState.close}
              >
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                {t("Securities")}
              </MenuItem>
            </ModuleSection>
            <ModuleSection
              module={["manager", "owner"]}
              organisationPackage="fxrates"
            >
              <MenuItem
                component={Link}
                to={`/${baseRoute}/fx`}
                onClick={popupState.close}
              >
                <ListItemIcon>
                  <LocalATMIcon />
                </ListItemIcon>
                {t("Foreign Exchange")}
              </MenuItem>
            </ModuleSection>
            <ModuleSection module="cashManagement">
              {user?.superuser && (
                <MenuItem
                  component={Link}
                  to={`/${baseRoute}/products`}
                  onClick={popupState.close}
                >
                  <ListItemIcon>
                    <BusinessCenterIcon />
                  </ListItemIcon>
                  {t("Products")}
                </MenuItem>
              )}
              {isCashManagement && (
                <MenuItem
                  component={Link}
                  to={`/${baseRoute}/counterparties`}
                  onClick={popupState.close}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  {t("Counterparties")}
                </MenuItem>
              )}
              {user?.superuser && (
                <MenuItem
                  component={Link}
                  to={`/${baseRoute}/funds_deprecated`}
                  onClick={popupState.close}
                >
                  <ListItemIcon>
                    <TrendingUpIcon />
                  </ListItemIcon>
                  {t("Funds")}
                </MenuItem>
              )}
            </ModuleSection>
          </DropdownMenu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
