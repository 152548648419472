import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import { withTheme } from "@material-ui/core/styles"
import BaseSwitch from "@material-ui/core/Switch"
import React from "react"

class Switch extends React.PureComponent<
  {
    label: string
    field: {
      name: string
    }
    form: {
      touched: boolean
      errors: any
      values: any
      setFieldValue: (name: string, value: any) => {}
    }
    required?: boolean
    fullWidth?: boolean
    theme: any
    FormControlLabelProps: any
  },
  { isChecked: boolean }
> {
  constructor(props) {
    super(props)

    const {
      form: { values },
      field: { name }
    } = props
    const initialState = values[name]
    this.state = { isChecked: initialState }
  }

  render() {
    const {
      label,
      field,
      form: { touched, errors, values, setFieldValue },
      required,
      fullWidth,
      theme,
      FormControlLabelProps,
      ...other
    } = this.props
    const { isChecked } = this.state
    const errorText = errors[field.name]
    const hasError = touched[field.name] && errorText !== undefined
    const errorColor = theme.palette.error.main
    const labelStyle = hasError ? { color: errorColor } : {}

    const controlProps = {
      checked: isChecked,
      value: values[field.name],
      onChange: event => {
        const { checked } = event.target
        this.setState({ isChecked: checked }, () => {
          setFieldValue(field.name, checked)
        })
      }
    }

    return (
      <FormControl
        fullWidth={fullWidth}
        required={required}
        error={hasError}
        {...other}
      >
        <FormControlLabel
          control={<BaseSwitch {...controlProps} />}
          label={<span style={labelStyle}>{label}</span>}
          {...FormControlLabelProps}
        />
        {hasError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    )
  }
}

export default withTheme(Switch)
