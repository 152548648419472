import useAuth from "@/components/auth/useAuth"
import { TextField } from "@/components/util/form/fields"
import Submit from "@/components/util/form/Submit"
import { VA_PUBLIC_SITE } from "@/helpers/const"
import { Grid, Typography } from "@material-ui/core"
import { Field, Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

export default function ResetForm() {
  let { token } = useParams<{ token: string }>()
  const { t } = useTranslation()

  const { reset } = useAuth()

  const ResetSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    password: Yup.string()
      //.min(8, t("Password must be at least eight characters long"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
        t("Password Complexity Requirements")
      )
      .max(100, t("Password must be 100 characters or fewer"))
      .required(t("Required"))
  })

  const initialValues = {
    email: "",
    password: ""
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetSchema}
      onSubmit={({ email, password }, { setStatus, setSubmitting }) => {
        reset(token, email, password)
          .then(user => {
            window.location.href = VA_PUBLIC_SITE
          })
          .catch(error => {
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.password) {
                const passwordErrors = error.response.data.errors.password.map(
                  function (msg) {
                    if (msg === "auth.password_used") {
                      msg = "Please do not reuse recent passwords"
                      // alert (t(msg))
                    }
                    return t(msg)
                  }
                )
                error.response.data.errors.password = passwordErrors
              }
              // alert(JSON.stringify(error.response.data.errors))
              setStatus(error.response.data.errors)
              setSubmitting(false)
            }
            return Promise.reject(error)
          })
      }}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4">Visual Alpha</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("Reset Password")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="email"
              label={t("Email")}
              component={TextField}
              type="email"
              autoComplete="username"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="password"
              label={t("Password")}
              component={TextField}
              type="password"
              autoComplete="new-password"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Submit label={t("Save")} fullWidth />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}
