import { PackageType } from "@/__generated__/globalTypes"
import { AxiosResponse } from "axios"
import React from "react"

export type Preferences = {
  asOfDate: string
  assetId: string
  assetType: string | null
  useLatestAsOfDate: boolean
}

export type User = {
  id: string
  name: string
  email: string
  role: "readonly" | "user" | "admin"
  title: string
  notificationsUnreadCount: number
  organisation: {
    id: string
    name: string
    organisationPackages: {
      data: {
        id: string
        type: PackageType
      }[]
    }
    modules: string[]
    type: string
    ref: string
    shell?: string | null
  } | null
  preferences: any
  asOfDate: string
  assetId: string
  assetType: string
  superuser: boolean
  agreed: boolean
  password_reset: boolean
}

interface AuthContextInterface {
  token: string | undefined
  user: User | null
  setUser: (user: User | null) => void
  login: (email: string, password: string) => Promise<void>
  login_with_token: (token: string) => void
  register: (user: any) => Promise<void>
  logout: () => void
  forgot: (email: string) => Promise<AxiosResponse<any>>
  reset: (token: string, email: string, password: string) => Promise<void>
  verify: (id: string, params: any) => Promise<AxiosResponse<any>>
  language: string
  setLanguage: (language: string) => void
  shell?: string | null
  updatePassword: (
    data: any,
    params?: any | null | undefined
  ) => Promise<AxiosResponse<any>>
  preferences: Preferences | undefined
  setFormPreferences: (preferences: Preferences) => void
  setAsOfDate: (asOfDate: string) => void
}

const AuthContext = React.createContext<AuthContextInterface | null>(null)

export default AuthContext
