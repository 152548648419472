import { useFormikContext } from "formik"
import React from "react"
import ConfirmationModal from "./ConfirmationModal"

const FormConfirmationModal = ({
  title,
  message,
  open,
  onClickSubmit,
  onClose = () => {},
  ...other
}: {
  title: string
  message: string
  open: boolean
  onClickSubmit?: () => any
  onClose?: () => void
}) => {
  const { submitForm } = useFormikContext()

  const confProps = {
    title,
    isForm: true,
    message,
    open,
    onClickSubmit: () => submitForm(),
    onClose,
    ...other
  }

  return <ConfirmationModal {...confProps} />
}

export default FormConfirmationModal
