import ModuleSection from "@/components/auth/ModuleSection"
import useAuth from "@/components/auth/useAuth"
import { assetsManagerPdf, assetsOwnerPdf } from "@/services/config"
import {
  Link as BaseLink,
  ListItemIcon,
  ListItemText,
  MenuItem
} from "@material-ui/core"
import HelpIcon from "@material-ui/icons/Help"
import React from "react"
import { useTranslation } from "react-i18next"
import DetermineBaseRoute from "./DetermineBaseRoute"

const CustomMenuHelpLink = () => {
  let { user } = useAuth()

  const { t } = useTranslation()
  const orgType = user?.organisation?.type
  const baseModule = DetermineBaseRoute(orgType || "manager")

  return (
    <ModuleSection module={baseModule}>
      <MenuItem
        component={BaseLink}
        color="inherit"
        target="_blank"
        href={
          baseModule === "manager" ? `${assetsManagerPdf}` : `${assetsOwnerPdf}`
        }
      >
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary={t("Help")} />
      </MenuItem>
    </ModuleSection>
  )
}

export default CustomMenuHelpLink
