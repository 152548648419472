import { IconButton as BaseButton, Tooltip } from "@material-ui/core"
import React from "react"

export default function IconButton(props) {
  const { tooltip, children, ...other } = props

  return (
    <Tooltip title={tooltip}>
      <BaseButton size="small" color="primary" variant="outlined" {...other}>
        {children}
      </BaseButton>
    </Tooltip>
  )
}
